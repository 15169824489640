/* eslint-disable fp/no-mutating-methods */

/* eslint-disable fp/no-mutation */

import React from 'react'
import i18next from 'i18next'
import {DEVICE_TYPE, DIALOG_TYPE, PROFILE_TYPE, TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'
import {
  IQGWScheduleSupportWithSavingsMode,
  hasHemsDevices,
  isCoExistingSite,
  isEvChargingModesSupported,
  isEvseSite,
} from 'lib/utils/siteDetails'
import {getDeviceToggleStatus, getErrorAlertDescription} from '../../molecules/DeviceBehaviour/dbCore'
import Icon from '../../../../utils/icon'
import {setPdValue} from '../../organisms/ProfileDetails/pdSlice'

const timerObj = {}

export function startTimer(id, dispatch) {
  const setPdSlice = obj => dispatch(setPdValue(obj))
  timerObj[id] = setTimeout(() => {
    setPdSlice({refreshEvStatus: true})
    clearTimeout(timerObj[id])
  }, 30000)
}

// Device Behaviour :: Bottom Drawer title
const getDbTitle = profile => {
  let desc = ''
  switch (profile) {
    case PROFILE_TYPE.COST_SAVINGS:
      desc = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp468') : i18next.t('bp226')
      break
    case PROFILE_TYPE.SELF_CONSUMPTION:
      desc = i18next.t('bp224')
      break
    case PROFILE_TYPE.BACKUP_ONLY:
      desc = i18next.t('bp228')
      break
    default:
      break
  }
  return desc
}

// Device Behaviour :: Info Details
export const getDeviceBehaviourInfoDetails = (profile, deviceObj, getInfoDescription, closeThisDialog) => {
  const dbInfoDescription = getInfoDescription(profile, deviceObj)
  const dbTitle = getDbTitle(profile)
  const obj = {
    className: 'db__device-behaviour-popup',
    type: DIALOG_TYPE.BOTTOM,
    showDialog: true,
    showCloseIcon: true,
    title: dbTitle,
    content: dbInfoDescription,
    buttons: null,
    onClickCloseIcon: closeThisDialog,
  }
  return obj
}

// Device profile :: get toggle status
export function getToggleStatus(enable, uuid, deviceChanges) {
  const changedDevice = deviceChanges?.find(item => uuid === item.uuid)
  const toggleStatus = changedDevice ? changedDevice.enable : enable
  return TOGGLE_SWITCH_STATUS[getDeviceToggleStatus(toggleStatus)]
}

// Device profile :: get active charger
export const getActiveCharger = (obj, deviceChanges, devices) => {
  let enabledCharger = null

  if (isCoExistingSite()) {
    if (deviceChanges.length) {
      enabledCharger = deviceChanges.find(device => device.enable && device.deviceType !== DEVICE_TYPE.THIRD_PARTY_EVSE)
    } else {
      enabledCharger = devices?.find(
        device =>
          device.deviceType !== DEVICE_TYPE.THIRD_PARTY_EVSE &&
          ((device.enable && device.profile === obj.profile) ||
            (device.status === 0 && !device.enable && device.profile === obj.profile))
      )
    }
    return devices?.find(device => device.uuid === enabledCharger?.uuid)
  }

  if (deviceChanges.length) {
    enabledCharger = deviceChanges.find(device => device.enable)
  } else {
    enabledCharger = devices?.find(
      device =>
        (device.enable && device.profile === obj.profile) ||
        (device.status === 0 && !device.enable && device.profile === obj.profile)
    )
  }
  return devices?.find(device => device.uuid === enabledCharger?.uuid)
}

// Device profile :: get error alert while enabling 2 profiles
export const getErrorAlert = (activeCharger, closeThisDialog) => {
  const dbErrorDescription = getErrorAlertDescription(activeCharger)
  const obj = {
    className: 'sp__tou-alert-popup e_dialog--button-w100',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: null,
    content: dbErrorDescription,
    buttons: null,
  }

  obj.buttons = [
    {
      value: i18next.t('bp85'),
      action: closeThisDialog,
      disabled: false,
      className: 'bp__ab--mobile-view-hr',
    },
  ]

  return obj
}

// Device profile :: check device behaviour changed
export const getIsDeviceBehaviourChanged = (selectedCharger, toggleState, isActive) => {
  let isChanged = toggleState
  if (isActive && selectedCharger.status !== 0) {
    isChanged = !(selectedCharger.enable === toggleState)
  } else if (selectedCharger.status === 0) {
    isChanged = true
  }
  return isChanged
}

export const displayStatus = tStatus => {
  let desc = ''
  switch (tStatus) {
    case TOGGLE_SWITCH_STATUS.OFF:
      desc = i18next.t('bp8')
      break
    case TOGGLE_SWITCH_STATUS.ON:
      desc = i18next.t('bp7')
      break
    case TOGGLE_SWITCH_STATUS.LOADING:
      desc = <Icon src="loaderV3" />
      break
    default:
      break
  }
  return desc
}

// Profile popup: function to check if None schedules and profile disabled
export const isNoneProfileDisabled = (deviceChanges, devices = []) => {
  const uuidsDisabled = deviceChanges.reduce((result, deviceChange) => {
    if (!deviceChange.loading && !deviceChange.enable) result.push(deviceChange.uuid)
    return result
  }, [])
  return devices?.some(device => device.activeSchedule === 'none' && uuidsDisabled.includes(device.uuid))
}

// Profile popup: text Content
function getNoneProfileDescription() {
  const desc = (
    <>
      <div className="alert__msg">{i18next.t('bp236')}</div>
      <div className="confirm__msg">{i18next.t('bp237')}</div>
      <div className="info__msg">{i18next.t('bp238')}</div>
    </>
  )
  return (
    <div className="e_dialog__body__wrapper">
      <Icon src="infoV2" />
      <div className="e_dialog__content">{desc}</div>
    </div>
  )
}

// Profile popup: None schedules and profile disabled Content
export const renderNoneProfileDisabledPopUp = ({showDialog, closeThisDialog}) => {
  const getNoneProfilePopupDescription = getNoneProfileDescription()
  const obj = {
    className: 'bp__none__profile-popup',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: null,
    content: getNoneProfilePopupDescription,
    onClickCloseIcon: closeThisDialog,
  }
  const promise = new Promise(resolve => {
    obj.buttons = [
      {
        value: i18next.t('bp105'),
        action: () => {
          closeThisDialog()
          resolve(false)
        },
        disabled: false,
        className: 'bp__ab--mobile-view',
      },
      {
        value: i18next.t('bp104'),
        action: () => {
          closeThisDialog()
          resolve(true)
        },
        disabled: false,
        className: 'bp__ab--mobile-view',
      },
    ]
  })
  obj && showDialog({...obj})
  return promise
}

export const checkDeviceProfileUpdating = details => {
  if (isEvChargingModesSupported()) {
    return false
  }
  const devices = getDevices(details)
  const deviceUpdate = devices?.find(device => device.status === -1)
  if (deviceUpdate) return true
  return false
}

export function checkDeviceProfileUpdatingDialog({closeThisDialog}) {
  const desc = i18next.t('bp266')

  const content = (
    <div className="bp__popup__wrapper">
      <Icon src="alert" />
      <div className="e_dialog__content">{desc}</div>
    </div>
  )

  const obj = {
    className: 'bp__alert-popup e_dialog--button-w100',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: null,
    content,
    buttons: null,
  }

  obj.buttons = [
    {
      value: i18next.t('bp46'),
      action: closeThisDialog,
      disabled: false,
      className: 'bp__ab--mobile-view-hr',
    },
  ]

  return obj
}

// get iqevse devices
function getIqevseDevices(details) {
  let devices = []
  if (isEvseSite()) {
    devices = details.devices.iqEvse.map(device => ({...device, deviceType: DEVICE_TYPE.IQ_EVSE}))
    return devices
  }
  return []
}

// get third party devices
function getThirdPartyDevices(details) {
  let devices = []
  if (hasHemsDevices()) {
    devices = details.devices.thirdPartyEvse.map(device => ({...device, deviceType: DEVICE_TYPE.THIRD_PARTY_EVSE}))
    return devices
  }
  return []
}

// Get devices (iqEvse, thirdPartyEvse)
export function getDevices(details) {
  let evDevices = []

  // coexistence of evse and 3rd party charger
  if (isCoExistingSite()) {
    const iqEvseDevices = getIqevseDevices(details)
    const thirdPartyEvseDevices = getThirdPartyDevices(details)
    evDevices = [...iqEvseDevices, ...thirdPartyEvseDevices]
    return evDevices
  }

  if (details?.devices?.iqEvse?.length) evDevices = getIqevseDevices(details)
  else if (details?.devices?.thirdPartyEvse?.length) evDevices = getThirdPartyDevices(details)
  return evDevices
}
