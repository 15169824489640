/* eslint-disable fp/no-mutation */

import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18next from 'i18next'
import {navigateBack} from 'lib/common'
import {batteryProfileSelector, setBpValue} from 'lib/store/slices/batteryProfile'
import {PAGE_NAME} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {showHeader} from 'lib/utils/pageDetails'
import {hasEncharge, isDTSupportedSite} from 'lib/utils/siteDetails'
import {isEnhoWeb, isWeb, setLocale} from 'lib/utils/utility'
import {Dialog} from '../../atoms/Dialog'
import ShowErrorDetails from '../../atoms/Error/showErrorDetails'
import Header from '../../atoms/Header'
import Loader from '../../atoms/Loader'
import {Toast} from '../../atoms/Toast'
import StormAlerts from '../../molecules/StormAlerts'
import {StormGuard} from '../../molecules/StormGuard'
import {init} from '../ProfileDetails/pdCore'
import './styles.scss'

/* *********************** *********************** *********************** *
 * Storm Guard Page
 * *********************** *********************** *********************** */

const StormGuardPage = props => {
  const {id} = props
  const loader = useSelector(state => state.components.loader)
  const dialogDetails = useSelector(state => state.components.dialogDetails)
  const batteryProfile = useSelector(batteryProfileSelector)

  const dispatch = useDispatch()
  const setBpDetails = obj => dispatch(setBpValue({...obj}))

  const subPageList = [...batteryProfile.subPage]

  // useEffect on mount
  useEffect(() => {
    setLocale()
    if (!batteryProfile.subPage.includes(PAGE_NAME.STORM_GUARD)) {
      init({dispatch})
      // setBpDetails({subPage: [...batteryProfile.subPage, PAGE_NAME.PROFILE, PAGE_NAME.STORM_GUARD]})
    }
    document.title = i18next.t('bp1')
  }, [])

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  const onClickBack = () => {
    if (subPageList.length > 0) {
      _.remove(subPageList, val => val === id)
      setBpDetails({subPage: subPageList})
    } else {
      navigateBack()
    }
  }

  // System Profile Tutorial :: onClick header action
  const onClickHeaderAction = () => {
    if (isWeb()) {
      onClickBack()
    }
  }

  const setActionTitle = () => {
    if (hasEncharge() || isDTSupportedSite()) {
      if (isEnhoWeb()) return <Icon src="close" />
      // if (!batteryProfile.invalidAccess && isSource(SOURCE_TYPE.ITK)) return <Icon src="tutorialHelp" />
      return null
    }
    return null
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  return (
    // Storm Guard Page
    <>
      {showHeader() && (
        <Header
          id={PAGE_NAME.STORM_GUARD}
          title={i18next.t('bp1')}
          actionTitle={setActionTitle()}
          onClickAction={onClickHeaderAction}
        />
      )}

      {!(batteryProfile.apiError || batteryProfile.invalidAccess) && (
        <div className="bp__page--wrapper">
          <div className="pd__profile-container">
            <div className="bp__page--with-action">
              <StormGuard />
              <StormAlerts />
            </div>
          </div>
        </div>
      )}

      {batteryProfile.apiError ? (
        <ShowErrorDetails />
      ) : (
        batteryProfile.invalidAccess && <ShowErrorDetails text={i18next.t('bp194')} subText={i18next.t('bp195')} />
      )}

      {!id && !(batteryProfile.apiError || batteryProfile.invalidAccess) && <Toast />}

      <Loader {...loader} />

      {/* Show Only for url type=storm-guard */}
      {subPageList.length === 0 && (
        <Dialog
          id={dialogDetails.id}
          className={`e_dialog__profile ${dialogDetails.className}`}
          ariaLabel={dialogDetails.ariaLabel}
          type={dialogDetails.type}
          showCloseIcon={dialogDetails.showCloseIcon}
          showDialog={dialogDetails.showDialog}
          title={dialogDetails.title}
          content={dialogDetails.content}
          buttons={dialogDetails.buttons}
          closeOnClickOverlay={dialogDetails.closeOnClickOverlay}
          onClickCloseIcon={dialogDetails.onClickCloseIcon}
        />
      )}
    </>
  )
}

StormGuardPage.propTypes = {
  id: PropTypes.string.isRequired,
}

StormGuardPage.defaultProps = {}

export default StormGuardPage
