/* eslint-disable fp/no-mutation */
import React, {useState} from 'react'
import i18next from 'i18next'
import {BATTERY_GRID_MODE} from 'lib/utils/constants'
import {isBatteryGridMode, isBatteryGridModeAvailable, isCountryDE, isNem3Supported} from 'lib/utils/siteDetails'
import Icon from '../../../../utils/icon'
import './styles.scss'

export const BatteryMode = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const importOnly = isBatteryGridMode(BATTERY_GRID_MODE.IMPORT_ONLY)
  const exportOnly = isBatteryGridMode(BATTERY_GRID_MODE.EXPORT_ONLY)
  const importAndExportOnly = isBatteryGridMode(BATTERY_GRID_MODE.IMPORT_EXPORT)
  const noGridInteraction = isBatteryGridMode(BATTERY_GRID_MODE.NO_GRID_INTERACTION)

  const handleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  // Hide Battery Mode
  if (!(isNem3Supported() || (isCountryDE() && isBatteryGridModeAvailable()))) {
    return false
  }

  const getBatteryMode = () => {
    let batteryModeData = {
      title: null,
      subTitle: null,
    }
    if (importAndExportOnly) {
      batteryModeData.title = i18next.t('bp483')
      batteryModeData.subTitle = i18next.t('bp485')
    } else if (noGridInteraction) {
      batteryModeData.title = i18next.t('bp484')
      batteryModeData.subTitle = i18next.t('bp486')
    } else if (importOnly) {
      batteryModeData.title = i18next.t('bp345')
      batteryModeData.subTitle = i18next.t('bp347')
    } else if (exportOnly) {
      batteryModeData.title = i18next.t('bp346')
      batteryModeData.subTitle = i18next.t('bp354')
    }
    return batteryModeData
  }

  const batteryModeData = getBatteryMode()

  const getCFGDetails = () => {
    let cfgDetails = {
      Icon: null,
      label: null,
    }

    if (importAndExportOnly || importOnly) {
      cfgDetails.Icon = 'checkIcon'
      cfgDetails.label = i18next.t('bp349')
    } else if (exportOnly || noGridInteraction) {
      cfgDetails.Icon = 'closeIcon'
      cfgDetails.label = i18next.t('bp358')
    }
    return (
      <>
        <Icon src={cfgDetails.Icon} />
        <span className="margin-left">{cfgDetails.label}</span>
      </>
    )
  }

  const getDTGDetails = () => {
    let dtgDetails = {
      icon: null,
      label: null,
    }

    if (importAndExportOnly || exportOnly) {
      dtgDetails.icon = 'checkIcon'
      dtgDetails.label = i18next.t('bp357')
    } else if (importOnly || noGridInteraction) {
      dtgDetails.icon = 'closeIcon'
      dtgDetails.label = i18next.t('bp350')
    }
    return (
      <>
        <Icon src={dtgDetails.icon} />
        <span className="margin-left"> {dtgDetails.label}</span>
      </>
    )
  }

  return (
    <div className="bp-battery-mode">
      <div className="bp-battery-mode-container">
        <div className="bp-battery-mode-container-flex">
          <p className="bp-battery-mode-container-title">
            <span className="bp-battery-mode-title"> {i18next.t('bp344')}:</span>
            <span className="value-margin-left">{batteryModeData.title}</span>
          </p>
          <div className="bp-battery-mode-container-icon" onClick={handleAccordion} aria-hidden="true">
            <span className={`arrow ${isExpanded ? 'arrow-up' : 'arrow-down'}`} />
          </div>
        </div>
        <div className="bp-battery-mode-container-description">{batteryModeData.subTitle}</div>
        {isExpanded && (
          <div className="bp__height-auto">
            <div className="bp-battery-mode-description">
              <div className="bp-battery-mode-description-container">
                <p className="title">{i18next.t('bp348')}</p>
                <p className="sub-title">{getCFGDetails()}</p>
                <p className="sub-title">{getDTGDetails()}</p>
              </div>
            </div>
            <div className="bp-battery-mode-information">
              <p>{i18next.t('bp351')}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
