/* eslint-disable fp/no-mutation */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import i18next from 'i18next'
import {handleError} from 'lib/common'
import {cancelBatteryRequest, setBatteryDetails} from 'lib/services/batteryDetailsAPI'
import {setDisclaimer} from 'lib/services/disclaimerAPI'
import {HTTPS_API_STATUS, HTTPS_REQUEST_TYPE} from 'lib/services/httpRequest'
import {batteryProfileSelector, setBpDetailsValue} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {setLoaderValue} from 'lib/store/slices/loaderSlice'
import {
  ACTION_TYPE,
  COMPONENT_SHORT_HAND,
  DIALOG_TYPE,
  DISCLAIMER_TYPE,
  PAGE_NAME,
  PENDING_REQ_KEY,
  TOAST_TYPE,
  TOGGLE_SWITCH_STATUS,
} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {
  disableCFGForDT,
  isSiteGridTied,
  showChargeFromGrid,
  isRestrictCFG,
  isShowGicDisclaimer,
  isSiteGridAgnostic,
  isIQGWScheduleSupportedSite,
} from 'lib/utils/siteDetails'
import {minutesToString, stringToMinutes} from 'lib/utils/timeDetails'
import {isUserHasAccess, isDemoUserBlocked} from 'lib/utils/userDetails'
import Analytics from 'lib/utils/analytics'
import {setSiteSettingDetails} from 'lib/store/slices/siteSettings'
import {isWeb, omit} from '../../../../utils/utility'
import {IntractiveButton} from '../../atoms/IntractiveButton'
import {showToast} from '../../atoms/Toast'
import {ToggleSwitch, toggleStatusInfo} from '../../atoms/ToggleSwitch'
import {init, updateBatterySliceValues} from '../../organisms/BatteryDetails/bdCore'
import {getRequestType} from '../../organisms/mqttRequest'
import {
  isAnyRequestPendingForChargeBatteryFromGrid,
  isAnyRequestPendingForProfile,
  isChargeFromGridRequestPending,
  isRequestPendingForChargeBatteryFromGrid,
} from '../../organisms/reqPending'
import {isStormAlertActive} from '../../organisms/StormGuardDetails/sgCore'
import {
  getCardDetails,
  getCbfgToggleStatus,
  getDescription,
  getInfoDescription,
  getItcDescription,
  getTimeErrorCbfg,
  showNewCFG,
  getRestrictCFGDescription,
  getScheduleEnabled,
  restrictUserToPerformCFGAction,
  showInfoIcon,
} from './cbfgCore'
import {setCbfgValue} from './cbfgSlice'
import {GridAgnostic} from './gridAgnostic'
import './styles.scss'
import {getGicDisclaimerDetails} from '../GicDisclaimer'

/* *********************** *********************** *********************** *
 *  Charge Battery From Grid
 * *********************** *********************** *********************** */

export const ChargeBatteryFromGrid = () => {
  const {
    loading,
    toggleStatus,
    isDisclaimerSelected,
    isDisclaimerLoading,
    chargeFromGridScheduleEnabled,
    chargeBeginTime,
    chargeEndTime,
    isCbfgInfoChanged,
    scheduleSupported,
  } = useSelector(state => state.components.chargeBatteryFromGrid)
  const isDbtgInfoChanged = useSelector(state => state.components.dischargeBatteryToGrid.isDbtgInfoChanged)
  const isDbtgLoading = useSelector(state => state.components.dischargeBatteryToGrid.loading)
  const isBatteryShutdownLoading = useSelector(state => state.components.batteryShutdown.loading)
  const isBsInfoChanged = useSelector(state => state.components.batteryShutdown.isBsInfoChanged)

  const batteryProfile = useSelector(batteryProfileSelector)
  const {details} = batteryProfile

  const cbfgTitle = i18next.t('bp59')
  const cbfgDescription = getDescription(toggleStatus, details)
  const cbfgDetails = getCardDetails(details)
  const cbfgButtonLabel = cbfgDetails.buttonLabel
  const cbfgButtonActionType = cbfgDetails.buttonActionType

  const chargeBeginTimeTxt = minutesToString(details?.chargeBeginTime)
  const chargeEndTimeTxt = minutesToString(details?.chargeEndTime)
  const lockCFGCard = restrictUserToPerformCFGAction({details, isDbtgLoading, loading})
  const dispatch = useDispatch()
  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const showLoader = val => dispatch(setLoaderValue({show: val}))
  const setLoading = val => dispatch(setCbfgValue({loading: val}))
  const setInProgress = val => dispatch(setCbfgValue({inProgress: val}))
  const setToggleStatus = val => dispatch(setCbfgValue({toggleStatus: val}))
  const setRequestType = val => dispatch(setCbfgValue({requestType: val}))
  const setIsDisclaimerSelected = val => dispatch(setCbfgValue({isDisclaimerSelected: val}))
  const setIsDisclaimerLoading = val => dispatch(setCbfgValue({isDisclaimerLoading: val}))
  const setScheduleEnabled = val => dispatch(setCbfgValue({chargeFromGridScheduleEnabled: val}))
  const setBatteryData = obj => dispatch(setBpDetailsValue(obj))
  const setSiteSettingsValue = obj => dispatch(setSiteSettingDetails(obj))
  const setCbfgInfoChanged = val => dispatch(setCbfgValue({isCbfgInfoChanged: val}))
  const resetCbfgData = obj => dispatch(setCbfgValue({...obj}))

  // useEffect on mount
  useEffect(() => {
    updateBatterySliceValues({source: COMPONENT_SHORT_HAND.CHARGE_BATTERY_FROM_GRID, dispatch, data: details})
    setScheduleEnabled(getScheduleEnabled(details))
    setLoading(isRequestPendingForChargeBatteryFromGrid(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT))
  }, [batteryProfile.loading])

  useEffect(() => {
    // reset cbfg data if user updating DTG / Battery Shutdown card
    if (isDbtgInfoChanged || isBsInfoChanged) {
      resetCbfgData({
        isCbfgInfoChanged: false,
        toggleStatus: details?.chargeFromGrid ? TOGGLE_SWITCH_STATUS.ON : TOGGLE_SWITCH_STATUS.OFF,
        chargeFromGridScheduleEnabled: details?.chargeFromGridScheduleEnabled || false,
        chargeBeginTime: {formatted12: minutesToString(details?.chargeBeginTime)},
        chargeEndTime: {formatted12: minutesToString(details?.chargeEndTime)},
      })
    }
  }, [isDbtgInfoChanged, isBsInfoChanged])

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  const getStatusInfoDetails = () => {
    const cbfgInfoDescription = getInfoDescription()
    const obj = {
      className: 'cbfg__info-popup',
      type: isWeb() ? DIALOG_TYPE.CENTER : DIALOG_TYPE.BOTTOM,
      showDialog: true,
      showCloseIcon: true,
      title: cbfgTitle,
      content: cbfgInfoDescription,
      buttons: null,
      onClickCloseIcon: closeThisDialog,
    }
    return obj
  }

  const getItcPopupDetails = checked => {
    const itcDescription = getItcDescription({checked, onChangeItcDisclaimerCheckbox})
    const obj = {
      className: 'cbfg__itc-popup',
      type: DIALOG_TYPE.CENTER,
      showDialog: true,
      showCloseIcon: false,
      title: cbfgTitle,
      content: itcDescription,
      onClickCloseIcon: closeThisDialog,
    }

    obj.buttons = [
      {
        value: i18next.t('bp99'),
        action: cancelItcDisclaimer,
        disabled: false,
        className: 'bp__ab--mobile-view',
      },
      {
        value: i18next.t('bp368'),
        action: () => enableItcDisclaimer(checked),
        disabled: !checked,
        className: 'bp__ab--mobile-view bp__nobg-color',
      },
    ]

    return obj
  }

  const getRestrictCFGPopupDetails = () => {
    const restrictCFGDescription = getRestrictCFGDescription()
    const obj = {
      className: 'cbfg__restrict-popup',
      type: DIALOG_TYPE.CENTER,
      showDialog: true,
      showCloseIcon: false,
      title: '',
      content: restrictCFGDescription,
      onClickCloseIcon: closeThisDialog,
    }

    obj.buttons = [
      {
        value: i18next.t('bp46'),
        action: closeThisDialog,
        disabled: false,
        className: 'bp__ab--mobile-view',
      },
    ]

    return obj
  }

  // On Change ITC Disclaimer Checkbox
  const onChangeItcDisclaimerCheckbox = checked => {
    if (checked) Analytics.sendClickEvent('CFG_ITC_Checkbox_Agreed', 'Battery_Settings')

    setIsDisclaimerSelected(checked)

    const obj = getItcPopupDetails(checked)
    const [, enabled] = obj.buttons
    enabled.disabled = !checked
    // showDialog(obj)

    dispatch(setDialogValue(obj))
  }

  // ITC Popup :: On Click Cancel
  const cancelItcDisclaimer = () => {
    Analytics.sendClickEvent('CFG_ITC_Cancel', 'Battery_Settings')
    closeThisDialog()
    setIsDisclaimerSelected(false)
    setToggleStatus(TOGGLE_SWITCH_STATUS.OFF)
  }

  // ITC Popup :: On Click Enable
  const enableItcDisclaimer = async checked => {
    if (checked) {
      closeThisDialog()
      setToggleStatus(TOGGLE_SWITCH_STATUS.ON)

      if (!details?.setIsDisclaimerSelected) {
        setIsDisclaimerLoading(true)
        showLoader(false)

        // Set ITC Disclaimer
        try {
          const resProfile = await setDisclaimer({[DISCLAIMER_TYPE.KEY]: DISCLAIMER_TYPE.ITC})
          if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
            setBatteryData({acceptedItcDisclaimer: true})
          } else {
            throw new Error('Error')
          }
        } catch (error) {
          console.error('[Error] enableItcDisclaimer', error)

          setToggleStatus(TOGGLE_SWITCH_STATUS.OFF)
          showToast({
            type: TOAST_TYPE.ERROR,
            message: i18next.t('bp68'),
            autoClose: 3000,
          })
        } finally {
          setIsDisclaimerLoading(false)
          showLoader(false)
        }
      }
    }
  }

  // Show ITC Popup
  const showItcPopup = () => {
    const obj = getItcPopupDetails(isDisclaimerSelected)
    showDialog(obj)
  }

  // Show GIC Disclaimer Dialog
  const showGicDisclaimer = () => {
    // GIC Dialog :: On Close Disclaimer
    const closeGicDisclaimer = () => {
      setToggleStatus(TOGGLE_SWITCH_STATUS.OFF)
      closeThisDialog()
    }

    // GIC Dialog :: On Confirm Disclaimer
    const confirmGicDisclaimer = async data => {
      closeThisDialog()
      showLoader(true)
      if (data) {
        try {
          const resProfile = await setDisclaimer({[DISCLAIMER_TYPE.KEY]: DISCLAIMER_TYPE.GIC}) // Enabled GIC Disclaimer
          if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
            setSiteSettingsValue({acceptedGICDisclaimer: true}) // Update state
            showLoader(false)
            showItcPopup()
          } else {
            throw new Error('Error')
          }
        } catch (error) {
          console.error('[Error] confirmGicDisclaimer', error)

          setToggleStatus(TOGGLE_SWITCH_STATUS.OFF)
          showLoader(false)
          showToast({
            type: TOAST_TYPE.ERROR,
            message: i18next.t('bp68'),
            autoClose: 3000,
          })
        }
      } else {
        showLoader(false)
        showItcPopup()
      }
    }

    const gicDisclaimerObj = getGicDisclaimerDetails({
      closeGicDisclaimer,
      confirmGicDisclaimer,
      redirectFrom: PAGE_NAME.BATTERY,
    })
    showDialog(gicDisclaimerObj)
  }

  // On Click Info Icon
  const onClickInfo = () => {
    const obj = getStatusInfoDetails()
    showDialog(obj)
  }

  // Handle Toggle Change
  const handleToggleChange = value => {
    if (isDemoUserBlocked()) return

    if (
      restrictUserToPerformCFGAction({
        details,
        isDbtgLoading,
        loading,
        showDialog,
        closeThisDialog,
        isBatteryShutdownLoading,
        showToastMsg: true,
      })
    ) {
      return
    }

    if (value === TOGGLE_SWITCH_STATUS.ON && isRestrictCFG()) {
      const obj = getRestrictCFGPopupDetails()
      showDialog(obj)
      return
    }

    if (disableCFGForDT(toggleStatus)) {
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp290'),
        autoClose: 3000,
      })
      return
    }

    if (value === TOGGLE_SWITCH_STATUS.ON) Analytics.sendClickEvent('CFG_enabled', 'Battery_Settings')
    else Analytics.sendClickEvent('CFG_disabled', 'Battery_Settings')

    if (isStormAlertActive(details)) {
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp244'),
        autoClose: 3000,
      })
      return
    }
    setToggleStatus(value)
    // setChargeUpto100(false)

    // Display GIC disclaimer dialog
    if (isUserHasAccess(false) && isShowGicDisclaimer(value, details)) {
      showGicDisclaimer()
      return
    }

    if (value === TOGGLE_SWITCH_STATUS.ON && !details?.chargeFromGrid) {
      showItcPopup()
    }
  }

  // Get Grid Agnostic Details
  const cbGridAgnostic = obj => {
    Analytics.sendClickEvent(`CFG_Schedule_Duration ${obj && obj.formatted12}`, 'Battery_Settings')
  }

  const onActionButtonClick = obj => {
    Analytics.sendClickEvent('CFG_Update', 'Battery_Settings')
    // Check User Access
    if (!isUserHasAccess()) {
      return
    }

    if (isStormAlertActive(details)) {
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp244'),
        autoClose: 3000,
      })
      return
    }

    if (obj.type === ACTION_TYPE.SAVE) {
      save()
    } else if (obj.type === ACTION_TYPE.CANCEL) {
      cancel()
    }
  }

  /* *********************** *********************** *********************** *
   * Functions
   * *********************** *********************** *********************** */

  // isInfoChanged
  function isInfoChanged() {
    const chargeFromGrid = getCbfgToggleStatus(details)
    const timeError = getTimeErrorCbfg(chargeBeginTime, chargeEndTime, chargeFromGridScheduleEnabled)
    let isValid = false

    if (disableCFGForDT(toggleStatus)) return false

    if (loading) {
      return true
    }

    if (isAnyRequestPendingForChargeBatteryFromGrid(details)) {
      return true
    }

    if (toggleStatus === TOGGLE_SWITCH_STATUS.OFF) {
      if (!isDisclaimerLoading && chargeFromGrid !== toggleStatus) {
        isValid = true
      }
    } else if (toggleStatus === TOGGLE_SWITCH_STATUS.ON) {
      if (timeError) {
        isValid = false
      } else if (
        chargeFromGrid !== toggleStatus ||
        (!showNewCFG() &&
          !isSiteGridTied() &&
          details?.chargeFromGridScheduleEnabled !== chargeFromGridScheduleEnabled &&
          !timeError) ||
        // (!isDisclaimerLoading && isDisclaimerSelected) ||
        ((chargeBeginTimeTxt !== chargeBeginTime.formatted12 || chargeEndTimeTxt !== chargeEndTime.formatted12) &&
          !timeError)
      ) {
        isValid = true
      }
    }
    validateCbfgInfoChanged()
    return isValid
  }

  function validateCbfgInfoChanged() {
    if (isCbfgInfoChanged) return

    const chargeFromGrid = getCbfgToggleStatus(details)
    if (
      chargeFromGrid !== toggleStatus ||
      chargeBeginTimeTxt !== chargeBeginTime.formatted12 ||
      chargeEndTimeTxt !== chargeEndTime.formatted12 ||
      (isSiteGridAgnostic() && details?.chargeFromGridScheduleEnabled !== chargeFromGridScheduleEnabled)
    ) {
      setCbfgInfoChanged(true)
    }
  }

  // Save
  async function save() {
    if (!_.isBoolean(details?.chargeFromGrid)) {
      showToast({
        type: TOAST_TYPE.ERROR,
        message: i18next.t('bp68'),
      })
      return
    }

    const obj = {
      chargeFromGrid: toggleStatus === TOGGLE_SWITCH_STATUS.ON,
    }

    if (toggleStatus === TOGGLE_SWITCH_STATUS.ON) {
      obj.acceptedItcDisclaimer = isDisclaimerSelected || details.acceptedItcDisclaimer
      obj.chargeBeginTime = stringToMinutes(chargeBeginTime.formatted12)
      obj.chargeEndTime = stringToMinutes(chargeEndTime.formatted12)
      if (!showNewCFG() && !isSiteGridTied()) {
        obj.chargeFromGridScheduleEnabled = chargeFromGridScheduleEnabled
      }
    }

    setRequestType(
      getRequestType(HTTPS_REQUEST_TYPE.MQTT, {details}) ? HTTPS_REQUEST_TYPE.MQTT : HTTPS_REQUEST_TYPE.API
    )
    setLoading(true)

    // Set Battery Details
    try {
      const resProfile = await setBatteryDetails(obj)
      if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
        console.debug('CFG Request submitted successfully')
      } else {
        throw new Error('Error')
      }
    } catch (error) {
      console.error('[Error] Charge Battery from Grid - Save', error)

      setRequestType(null)
      setLoading(false)
      setInProgress(false)

      showToast({
        type: TOAST_TYPE.ERROR,
        message: i18next.t('bp68'),
        autoClose: 3000,
      })
    }
  }

  // Cancel
  async function cancel() {
    const obj = {}
    obj.requestedConfig = omit(details.requestedConfig, [
      'chargeFromGrid',
      'chargeBeginTime',
      'chargeEndTime',
      'acceptedItcDisclaimer',
      'chargeFromGridScheduleEnabled',
      'cfgControl',
    ])

    setRequestType(HTTPS_REQUEST_TYPE.API)
    setLoading(true)

    // Cancel Battery Request
    try {
      const resProfile = await cancelBatteryRequest({type: 'chargeFromGrid'})
      if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
        showToast({
          type: TOAST_TYPE.SUCCESS,
          message: i18next.t('bp76'),
          autoClose: 3000,
        })

        setBatteryData(obj)
        setToggleStatus(getCbfgToggleStatus({...details, ...obj}))
        setLoading(false)
      } else {
        throw new Error('Error')
      }
    } catch (e) {
      const err = e.error || e

      setLoading(false)
      handleError({error: e, showToast, dispatch})

      if (err.response.status === 409) {
        init({dispatch})
      }
    } finally {
      setRequestType(null)
      setInProgress(false)
    }
  }

  /* *********************** *********************** *********************** *
   * Render Function
   * *********************** *********************** *********************** */

  // Render Toggle
  const renderToggle = () => {
    const tStatus = toggleStatus
    const tLoading = loading || isAnyRequestPendingForChargeBatteryFromGrid(details)
    const disabled =
      disableCFGForDT(toggleStatus) || (isIQGWScheduleSupportedSite() && isAnyRequestPendingForProfile(details))
        ? false
        : tLoading

    const lockCFGToggle =
      (tLoading || disableCFGForDT(toggleStatus) || lockCFGCard) && toggleStatus === TOGGLE_SWITCH_STATUS.ON

    return (
      <div className="cbfg__status__toggle-container">
        {isWeb() && (
          <div className={`cbfg__status-text ${lockCFGToggle && 'cfg__profile--savings'}`}>
            {toggleStatusInfo({loading: tLoading, status: toggleStatus})}
          </div>
        )}
        <ToggleSwitch
          key="cbfg-toggle"
          id="cbfg-toggle"
          className={`${lockCFGToggle && 'lock-cfg-toggle'}`}
          status={tStatus}
          disabled={disabled}
          handleChange={handleToggleChange}
        />
      </div>
    )
  }

  // Render Status Info
  const renderStatusInfo = () => {
    const tLoading =
      loading ||
      isChargeFromGridRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
      isChargeFromGridRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)

    const isChargingFromGridLocked =
      (tLoading || disableCFGForDT(toggleStatus) || lockCFGCard) && toggleStatus === TOGGLE_SWITCH_STATUS.ON

    return (
      <>
        <div className={`cbfg__status-text ${isChargingFromGridLocked && 'cfg__profile--savings'}`}>
          {toggleStatusInfo({loading: tLoading, status: toggleStatus})}
        </div>

        {showInfoIcon(details) && (
          <div className="cbfg__info" onClick={onClickInfo} onKeyDown={() => {}} role="button" tabIndex="0">
            <Icon src="info" />
          </div>
        )}
      </>
    )
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  // Hide Charge Battery From Grid
  if (!showChargeFromGrid()) {
    return null
  }

  return (
    // Charge Battery From Grid
    <section id="bp-charge-battery-from-grid" className="bp__card-view">
      <div className="bp__card-view__wrapper">
        <div className="cbfg__title-toggle">
          <div className="bp__card-view--title">
            {cbfgTitle}{' '}
            {isWeb() && showInfoIcon(details) && (
              <span onClick={onClickInfo} onKeyDown={onClickInfo} role="button" tabIndex={0}>
                <Icon src="info" />
              </span>
            )}
          </div>
          <div className="cbfg__toggle">{renderToggle()}</div>
        </div>

        {!isWeb() && <div className="cbfg__status-info">{renderStatusInfo()}</div>}
        {cbfgDescription && <div className="bp__card-view--desc">{cbfgDescription}</div>}

        {scheduleSupported && <GridAgnostic status={toggleStatus} cb={cbGridAgnostic} lockCFGCard={lockCFGCard} />}
      </div>

      {isInfoChanged() && (
        <IntractiveButton
          className="cbfg__button ib__btn"
          type={cbfgButtonActionType}
          label={cbfgButtonLabel}
          loading={loading}
          disabled={!isInfoChanged()}
          onClick={onActionButtonClick}
        />
      )}
    </section>
  )
}

ChargeBatteryFromGrid.propTypes = {}

ChargeBatteryFromGrid.defaultProps = {}
