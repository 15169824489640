/* eslint-disable fp/no-mutation */
import _ from 'lodash'
import React from 'react'
import i18next from 'i18next'
import {HTTPS_REQUEST_TYPE} from 'lib/services/httpRequest'
import {getProfileDetails} from 'lib/services/profileDetailsAPI'
import {getSiteSettings} from 'lib/services/siteSettingsAPI'
import {getBatteryProfileDetails, getSiteSettingsDetails} from 'lib/store'
import {setBpDetailsValue, setBpValue} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {setLoaderValue} from 'lib/store/slices/loaderSlice'
import {setSiteSetting, setSiteSettingDetails} from 'lib/store/slices/siteSettings'
import {FEATURE_KEYS, isFeatureSupported} from 'lib/utils/featureKeys'
import Icon from 'lib/utils/icon'
import {
  getRequestFailedAlertDialog,
  showRequestChangeErrorDialog,
  showScheduleHandlingMsg,
} from 'lib/utils/mqttAlertDetails'
import {isBatteryCalibrationProgress, isGatewayNotReporting} from 'lib/utils/siteDetails'
import {isActivePage, omit} from 'lib/utils/utility'
import {
  ACTION_TYPE,
  DIALOG_TYPE,
  PAGE_NAME,
  PENDING_REQ_KEY,
  PROFILE_STATUS,
  TOAST_TYPE,
} from '../../../../utils/constants'
import {showToast} from '../../atoms/Toast'
import {getAfterPeakToggleStatus} from '../../molecules/EditAfterPeakHours/eaphCore'
import {setSaValue} from '../../molecules/StormAlerts/saSlice'
import {isResponseHasUpdatedBatteryBackupPercentage} from '../BatteryDetails/bdCore'
import {getStormGuardToggleStatus, isStormAlertActive} from '../StormGuardDetails/sgCore'
import {setSgValue} from '../StormGuardDetails/sgSlice'
import {initMqtt} from '../mqttRequest'
import {
  isAnyRequestPendingForBatteryShutdown,
  isProfileRequestPending,
  isRequestPendingForProfile,
  isThisProfileRequestPending,
} from '../reqPending'
import {isDrEventActive} from './drEventCore'
import {setPdValue} from './pdSlice'
import './styles.scss'

export function getProfileCurrentDetails(details, profile, inProgress, requestType) {
  if (isActiveProfile(details, profile)) {
    let buttonActionType = ACTION_TYPE.SAVE
    let buttonLabel = i18next.t('bp21')
    let subType = null

    if (isActiveProfile(details?.requestedConfigMqtt, profile) && isThisProfileRequestPending(details, profile)) {
      subType = PROFILE_STATUS.PROCESSING
      buttonLabel = i18next.t('bp20')
    }

    if (isActiveProfile(details?.requestedConfig, profile) && isThisProfileRequestPending(details, profile)) {
      subType = PROFILE_STATUS.PENDING
      buttonActionType = ACTION_TYPE.CANCEL
      buttonLabel = i18next.t('bp49')
    }

    return {
      profile,
      type: PROFILE_STATUS.ACTIVE,
      subType,
      typeText: null,
      buttonLabel,
      buttonActionType,
      requestType: getRequestType(details),
    }
  }

  if (
    (inProgress === profile && requestType === HTTPS_REQUEST_TYPE.MQTT) ||
    isActiveProfile(details?.requestedConfigMqtt, profile)
  ) {
    return {
      profile,
      type: PROFILE_STATUS.PROCESSING,
      typeText: null,
      buttonLabel: i18next.t('bp20'),
      buttonActionType: ACTION_TYPE.SAVE,
      requestType: getRequestType(details),
    }
  }

  if (
    (inProgress === profile && requestType === HTTPS_REQUEST_TYPE.API) ||
    isActiveProfile(details?.requestedConfig, profile)
  ) {
    return {
      profile,
      type: PROFILE_STATUS.PENDING,
      typeText: i18next.t('bp100'),
      buttonLabel: isThisProfileRequestPending(details, profile) ? i18next.t('bp49') : i18next.t('bp20'),
      buttonActionType: isThisProfileRequestPending(details, profile) ? ACTION_TYPE.CANCEL : ACTION_TYPE.SAVE,
      requestType: getRequestType(details),
    }
  }

  return {
    buttonLabel: i18next.t('bp20'),
    buttonActionType: ACTION_TYPE.SAVE,
    requestType: getRequestType(details),
  }
}

export function getRequestType(details) {
  if (isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return HTTPS_REQUEST_TYPE.MQTT
  }

  if (isProfileRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return HTTPS_REQUEST_TYPE.API
  }

  return null
}

export function isActiveProfile(details, profile) {
  return details?.profile === profile
}

// Get button action type
export function getButtonActionType(details, pcButtonActionType, disableActionButton) {
  return isStormAlertActive(details) ||
    isDrEventActive(details) ||
    isBatteryCalibrationProgress() ||
    (disableActionButton && pcButtonActionType !== ACTION_TYPE.CANCEL)
    ? ACTION_TYPE.DISABLED
    : pcButtonActionType
}

// Get calibration progress details
export function getCalibrationProgressDetails() {
  const desc = 'Battery calibration in progress. Please update the settings after battery charges to 100%.'
  return (
    <div className="cp_alert-popup__wrapper">
      <Icon src="alert" />
      <div className="cp_alert-popup__wrapper-content">{desc}</div>
    </div>
  )
}

// Calibration Alert popup
export function getCalibrationAlertDetails(closeThisDialog) {
  const cpInfoDescription = getCalibrationProgressDetails()
  const obj = {
    className: 'cp_alert-popup',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: null,
    content: cpInfoDescription,
    buttons: null,
  }

  obj.buttons = [
    {
      value: i18next.t('bp46'),
      action: closeThisDialog,
      disabled: false,
      className: 'bp__ab--mobile-view-hr',
    },
  ]

  return obj
}

// Show dialog on card action button
export function showActiveRequestDialog({profile, inProgress, details}) {
  if ((inProgress && inProgress !== profile) || isRequestPendingForProfile(details)) {
    return true
  }

  return false
}

export function getActiveRequestAlertDetails({details, closeThisDialog}) {
  let desc = i18next.t('bp258')

  if (isAnyRequestPendingForBatteryShutdown(details)) {
    desc = i18next.t('bp199')
  } else if (
    getRequestType(details) === HTTPS_REQUEST_TYPE.API &&
    !(details.systemTask || details.requestedConfig.systemTask)
  ) {
    desc = i18next.t('bp198')
  }

  const content = (
    <div className="bp__popup__wrapper">
      <Icon src="alert" />
      <div className="e_dialog__content">{desc}</div>
    </div>
  )

  const obj = {
    className: 'bp__alert-popup e_dialog--button-w100',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: null,
    content,
    buttons: null,
  }

  obj.buttons = [
    {
      value: i18next.t('bp46'),
      action: closeThisDialog,
      disabled: false,
      className: 'bp__ab--mobile-view-hr',
    },
  ]

  return obj
}

// Init Profile
export async function init({dispatch, noLoaderBg}) {
  const showLoader = val => dispatch(setLoaderValue({show: val, noBg: noLoaderBg || false}))
  const setBpDetails = obj => dispatch(setBpValue({...obj}))
  const setSiteSettingLoading = val => dispatch(setSiteSetting({loading: val}))
  const setSiteSettingsDetails = obj => dispatch(setSiteSettingDetails(obj))

  showLoader(true)

  // Get APIs response
  try {
    // Initiate MQTT connection
    const wsObj = {
      liveDebug: false,
      keepAlive: true,
      dispatch,
    }
    initMqtt({dispatch, wsObj})

    // Get APIs response for site settings and profile details
    const res = await Promise.all([getSiteSettings(), getProfileDetails()])

    // Site Settings - Changes
    const resSiteSettings = res[0]
    setSiteSettingsDetails(resSiteSettings.data)

    const bpDetails = {}
    bpDetails.apiError = false
    bpDetails.invalidAccess = !isFeatureSupported(FEATURE_KEYS.HEMS_BATTERY_PROFILE)

    setBpDetails(bpDetails)
    setSiteSettingLoading(false)

    // Profile Details - Changes
    const resProfile = res[1]
    updateProfileSliceValues({source: HTTPS_REQUEST_TYPE.API, dispatch, data: resProfile.data})
    setBpDetails({loading: false})
    showLoader(false)
  } catch (e) {
    setBpDetails({apiError: true})
    showLoader(false)
    console.error('[Profile] init', e)
  }
}

// Update Values
export function updateProfileSliceValues({type, source, dispatch, data}) {
  const details = getBatteryProfileDetails()
  const siteSettingsDetails = getSiteSettingsDetails()
  const setBatteryData = obj => dispatch(setBpDetailsValue(obj))
  const setSiteSettingsDetails = obj => dispatch(setSiteSettingDetails(obj))
  const setSgSlice = obj => dispatch(setSgValue(obj))
  const setSaSlice = obj => dispatch(setSaValue(obj))
  const setPdSlice = obj => dispatch(setPdValue(obj))

  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))

  let reqKey = null

  // Storm Guard
  if (source === HTTPS_REQUEST_TYPE.API || type === 'storm') {
    const reqToggleState = data?.requestedConfigMqtt?.stormGuardState || data?.requestedConfig?.stormGuardState
    const toggleState = data?.stormGuardState

    setSgSlice({
      loading: (reqToggleState && reqToggleState !== toggleState) === true,
      stormGuardState: getStormGuardToggleStatus(reqToggleState || toggleState),
    })

    if (data?.stormAlerts) {
      setSaSlice({stormAlerts: data.stormAlerts, criticalAlertActive: data.criticalAlertActive})
    }

    if (_.isBoolean(data?.evseStormEnabled)) {
      setSgSlice({evseStormEnabled: data.evseStormEnabled})
    }
  }

  // Savings
  if (source === HTTPS_REQUEST_TYPE.API || isResponseHasUpdatedOperationModeSubType(data) || source === 'savings') {
    setPdSlice({operationModeSubType: getAfterPeakToggleStatus(data)})
  }

  // Profile Details - API
  if (source === HTTPS_REQUEST_TYPE.API) {
    setBatteryData(data)
  }

  // Profile Details - mqtt/task
  if (source === HTTPS_REQUEST_TYPE.MQTT || source === HTTPS_REQUEST_TYPE.TASK) {
    const obj = {}
    const resData = omit(data, ['requestType', 'status', 'deviceDetails'])
    let toastType = null
    let toastMessage = null

    if (source === HTTPS_REQUEST_TYPE.MQTT) {
      reqKey = 'requestedConfigMqtt'
      obj.requestedConfig = {}
      setPdSlice({loading: false})
    } else if (source === HTTPS_REQUEST_TYPE.TASK) {
      reqKey = 'requestedConfig'
      obj.requestedConfigMqtt = {}
    }

    // Success
    if (data.status === 1) {
      toastType = TOAST_TYPE.SUCCESS
      toastMessage =
        siteSettingsDetails.isHemsActivatedFromUI || showScheduleHandlingMsg({data})
          ? i18next.t('bp435')
          : i18next.t('bp27')
      obj[reqKey] = {...details[reqKey]}

      if (isResponseHasUpdatedBatteryBackupPercentage(resData)) {
        obj[reqKey] = omit(obj[reqKey], ['profile', 'batteryBackupPercentage', 'systemTask'])
      } else {
        obj[reqKey] = omit(obj[reqKey], ['profile', 'systemTask'])
      }

      if (isResponseHasUpdatedOperationModeSubType(resData)) {
        if (isResponseHasUpdatedBatteryBackupPercentage(resData)) {
          obj[reqKey] = omit(obj[reqKey], ['profile', 'batteryBackupPercentage', 'operationModeSubType', 'systemTask'])
        } else {
          obj[reqKey] = omit(obj[reqKey], ['profile', 'operationModeSubType', 'systemTask'])
        }
      }

      if (resData.profile !== details.profile) {
        const {previousBatteryBackupPercentage} = details
        resData.previousBatteryBackupPercentage = {...previousBatteryBackupPercentage}
        resData.previousBatteryBackupPercentage[details.profile] = details.batteryBackupPercentage
      }

      // Update Battery Profile Slice
      setBatteryData({...resData, ...obj})

      // Update Site Setting slice
      if (siteSettingsDetails.isHemsActivatedFromUI) {
        setSiteSettingsDetails({...siteSettingsDetails, isHemsActivatedFromUI: false})
      }

      // Update Profile Slice Values
      setPdSlice({requestType: null, inProgress: null, isAfterPeakLoading: null, openCard: data?.profile})
    }
    // Failed
    else if (data.status === 0) {
      toastType = TOAST_TYPE.ERROR
      toastMessage = i18next.t('bp29')
      obj[reqKey] = {...getBatteryProfileDetails()[reqKey]}

      if (isResponseHasUpdatedBatteryBackupPercentage(resData)) {
        obj[reqKey] = omit(obj[reqKey], ['profile', 'batteryBackupPercentage'])
      } else {
        obj[reqKey] = omit(obj[reqKey], ['profile'])
      }

      if (isResponseHasUpdatedOperationModeSubType(resData)) {
        if (isResponseHasUpdatedBatteryBackupPercentage(resData)) {
          obj[reqKey] = omit(obj[reqKey], ['profile', 'batteryBackupPercentage', 'operationModeSubType'])
        } else {
          obj[reqKey] = omit(obj[reqKey], ['profile', 'operationModeSubType'])
        }
      }

      // Update Battery Profile Slice
      setBatteryData({...obj})

      // Update Profile Slice Values
      if (source === HTTPS_REQUEST_TYPE.TASK) {
        setPdSlice({loading: false})
      }
      setPdSlice({isAfterPeakLoading: null})
      setTimeout(() => setPdSlice({inProgress: null, requestType: null}), 1000)
    }
    // Pending
    else if (data.status === -1) {
      toastType = TOAST_TYPE.INFO
      toastMessage = i18next.t('bp28')

      // Update Battery Profile Slice
      obj[reqKey] = {...getBatteryProfileDetails()[reqKey], ...resData}

      setBatteryData({...obj})

      // Update Profile Slice Values
      if (source === HTTPS_REQUEST_TYPE.TASK) {
        setPdSlice({loading: false})
      }
      setPdSlice({requestType: HTTPS_REQUEST_TYPE.API})
      // setTimeout(() => setPdSlice({inProgress: resData.profile}), 1000)
    }

    // Show error dialog
    if (isActivePage(PAGE_NAME.PROFILE) && showRequestChangeErrorDialog({data})) {
      const reqData = {...data}
      reqData.redirectFrom = PAGE_NAME.PROFILE
      reqData.isGatewayNotReporting = isGatewayNotReporting()

      showDialog(getRequestFailedAlertDialog({details: reqData, closeThisDialog}))
    } else if (isActivePage(PAGE_NAME.PROFILE) && !(data.systemTask && data.status === -1)) {
      // Show Toast
      showToast({
        type: toastType,
        message: toastMessage,
        autoClose: 3000,
      })
    }
  } else if (type === 'storm') {
    const resData = omit(data, ['type', 'status', 'deviceDetails'])
    resData.stormAlertMessage = resData.stormAlertMessage || null
    resData.showStormGuardAlert = resData.showStormGuardAlert || false

    // Update Battery Profile Slice
    setBatteryData({...resData})
  }
}

function isResponseHasUpdatedOperationModeSubType(resData) {
  return (
    !_.isNil(resData.operationModeSubType) &&
    resData.operationModeSubType !== getBatteryProfileDetails().operationModeSubType
  )
}
