/* eslint-disable fp/no-mutating-methods */
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {getTheme} from 'lib/hook/useTheme'
import {DIALOG_TYPE} from 'lib/utils/constants'
import {getAriaLabel} from 'lib/utils/utility'
import Icon from '../../../../utils/icon'
import './styles.scss'

/*
  --- --- --- --- --- ---
  How to use <Dialog />
  --- --- --- --- --- ---
  <Dialog
    id="String"
    className="String"
    type="top|bottom|center"
    title="String"
    showCloseIcon={true|false}
    closeOnClickOverlay={true|false}
    content="String|Object"
    buttons={[{ rowValue: 'OK', value: 'OK', action: () => { }, disabled:'true|false', className:'bp__ab--outline' }]}
    onClickCloseIcon={onClickCloseButton}
    showDialog={true|false}
    ariaLabel="String"
  />

  No Header
    title={null} or Don't pass "title"
    showCloseIcon={false}

  No Footer
    buttons: [] or Don't pass "buttons"
*/

export class Dialog extends React.Component {
  constructor() {
    super()

    this.dialogRef = React.createRef()
    this.state = {
      showDialog: false,
    }
  }

  componentDidMount() {
    this.updateState()
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateState()
    }
  }

  onClose() {
    const {type, onClickCloseIcon} = this.props
    // const node = this.dialogRef.current
    // const [nodeChild] = node.children

    if (type === DIALOG_TYPE.TOP) {
      // nodeChild.classList.add('slide-up')
    } else if (type === DIALOG_TYPE.BOTTOM) {
      // nodeChild.classList.add('slide-down')
    }

    // setTimeout(() => {
    this.setState({showDialog: false})
    onClickCloseIcon()
    // }, 500)
  }

  onClickOverlay(ele) {
    const {closeOnClickOverlay} = this.props

    if (ele?.target?.classList?.contains('e_dialog') && closeOnClickOverlay) {
      this.onClose()
    }
  }

  getClassNames() {
    const {className, type} = this.props
    const classes = ['e_dialog', className]

    if (type === DIALOG_TYPE.TOP) {
      classes.push('e_dialog--top-view')
    } else if (type === DIALOG_TYPE.BOTTOM) {
      classes.push('e_dialog--bottom-view')
    } else if (type === DIALOG_TYPE.CENTER) {
      classes.push('e_dialog--mobile-view')
    }

    return classes.join(' ')
  }

  getButtonClassName(button) {
    const className = []
    className.push('bp__action-button bp__ab--round-corner')

    if (button) {
      if (button.disabled) {
        className.push('bp__ab--disabled')
      }
      if (button.className) {
        className.push(button.className)
      }
    }

    return className.join(' ')
  }

  getButtonContent(ariaLabel) {
    const {buttons} = this.props

    return (
      buttons &&
      buttons.map((button, i) => {
        const className = this.getButtonClassName(button)
        const thisAriaLabel = button.rowValue
          ? `${ariaLabel}__action-button-${button.rowValue.toLowerCase()}`
          : `${ariaLabel}__action-button__${i + 1}`

        return (
          <button
            role="button"
            key={`d-${i + 1}`}
            className={className}
            aria-label={thisAriaLabel}
            disabled={button.disabled}
            value={button.value}
            onClick={() => {
              button.action()
            }}>
            {button.value}
          </button>
        )
      })
    )
  }

  updateState() {
    const {showDialog} = this.props
    this.setState({showDialog})
  }

  render() {
    const {id, title, showCloseIcon, content, buttons, ariaLabel} = this.props
    const {showDialog} = this.state
    const domId = document.body
    const thisAriaLabel =
      ariaLabel ||
      getAriaLabel('bp__dialog', id, `${this.props.className?.replace('e_dialog__', '').replace(/ /g, '__')}`)
    const buttonContent = this.getButtonContent(thisAriaLabel)
    const theme = getTheme()
    const className = this.getClassNames()
    let modal = null

    if (!showDialog) {
      return modal
    }

    // eslint-disable-next-line fp/no-mutation
    modal = (
      <div id={id} className={`bp__dialog ${theme}`} ref={this.dialogRef} role="dialog">
        <div
          className={className}
          onClick={ele => this.onClickOverlay(ele)}
          onKeyDown={() => {}}
          role="document"
          tabIndex="0">
          <div className="e_dialog__wrapper">
            {(title || showCloseIcon) && (
              <div className="e_dialog__header" aria-label={`${thisAriaLabel}__header`}>
                <div className="e_dialog__header__title">{title}</div>

                {showCloseIcon && (
                  <button type="button" className="e_dialog__header--close-button" onClick={() => this.onClose()}>
                    <Icon src="close" width={12} height={12} />
                  </button>
                )}
              </div>
            )}

            <div className="e_dialog__body" aria-label={`${thisAriaLabel}__body`}>
              {content}
            </div>

            {buttons && buttons.length > 0 && (
              <div className="e_dialog__footer" aria-label={`${thisAriaLabel}__footer`}>
                <div className="e_dialog__footer__wrapper">{buttonContent}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    )

    if (domId) {
      return ReactDOM.createPortal(modal, domId)
    }
    return modal
  }
}

Dialog.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf([DIALOG_TYPE.TOP, DIALOG_TYPE.CENTER, DIALOG_TYPE.BOTTOM]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  showCloseIcon: PropTypes.bool,
  closeOnClickOverlay: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttons: PropTypes.arrayOf(Object),
  onClickCloseIcon: PropTypes.func,
  showDialog: PropTypes.bool,
  ariaLabel: PropTypes.string,
}

Dialog.defaultProps = {
  id: null,
  className: '',
  type: DIALOG_TYPE.BOTTOM,
  title: '',
  showCloseIcon: true,
  closeOnClickOverlay: false,
  content: '',
  buttons: [],
  onClickCloseIcon: () => {},
  showDialog: false,
  ariaLabel: '',
}
