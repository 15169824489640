/* eslint-disable fp/no-mutation */

import React from 'react'
import i18next from 'i18next'
import {HTTPS_REQUEST_TYPE} from 'lib/services/httpRequest'
import {getBatteryProfileDetails} from 'lib/store'
import {setBpDetailsValue, setBpDevicesValues} from 'lib/store/slices/batteryProfile'
import {DEVICE_TYPE, PAGE_NAME, PROFILE_TYPE, TOAST_TYPE} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {isActivePage, omit} from 'lib/utils/utility'
import {
  isCoExistingSite,
  getDeviceType,
  isHemsSite,
  IQGWScheduleSupportWithSavingsMode,
  isPvAndCtWithEvseSite,
} from 'lib/utils/siteDetails'
import {getDevices} from '../../atoms/EvTopUpSchedule/evScheduleCore'
import {showToast} from '../../atoms/Toast'
import {setPdValue} from '../../organisms/ProfileDetails/pdSlice'
import {removeDevice, setDeviceLoading, setDeviceUpdate, setDeviceUpdateDisable} from './dbSlice'

const timerObj = {}

export function getDeviceToggleStatus(enable) {
  return enable ? 'ON' : 'OFF'
}

export function updateDeviceProfileSliceValues({source, dispatch, data}) {
  const setDeviceData = obj => dispatch(setBpDevicesValues(obj))
  const removeDeviceUpdate = obj => dispatch(removeDevice(obj))
  const setDeviceLoader = obj => dispatch(setDeviceLoading(obj))
  const setDeviceChangeDisable = obj => dispatch(setDeviceUpdateDisable(obj))
  const setBatteryData = obj => dispatch(setBpDetailsValue(obj))
  const setPdSlice = obj => dispatch(setPdValue(obj))

  let toastType = null
  let toastMessage = null
  const reqKey = 'requestedConfigMqtt'
  const obj = {}
  // Device Profile Details - Mqtt
  if (source === HTTPS_REQUEST_TYPE.MQTT) {
    const resData = omit(data, ['requestType', 'status', 'deviceDetails'])
    obj.requestedConfigMqtt = {}
    obj.requestedConfig = {}

    if (data.chargeMode) {
      removeDeviceUpdate({profile: data?.profile, uuid: data?.deviceDetails?.uuid})
      clearTimer(data?.deviceDetails?.uuid)
      const deviceDetails = {
        ...data?.deviceDetails,
        profile: data?.profile,
        status: 1,
        chargeMode: data?.chargeMode,
      }
      setDeviceData(deviceDetails)
      setDeviceChangeDisable({deviceChangeDisable: false})
      setBatteryData({...resData, ...obj})
      clearRequest(dispatch)
    }

    if (data?.status === 1) {
      removeDeviceUpdate({profile: data?.profile, uuid: data?.deviceDetails?.uuid})
      clearTimer(data?.deviceDetails?.uuid)
      const deviceDetails = {
        ...data?.deviceDetails,
        profile: data?.profile,
        status: 1,
        activeSchedule: data?.deviceDetails?.activeSchedule || 'Disabled',
      }
      setDeviceData(deviceDetails)
      setDeviceChangeDisable({deviceChangeDisable: false})
      setBatteryData({...resData, ...obj})
      clearRequest(dispatch)
      toastType = TOAST_TYPE.SUCCESS
      toastMessage = i18next.t('bp251')
      if (isActivePage(PAGE_NAME.PROFILE)) {
        showToast({
          type: toastType,
          message: toastMessage,
          autoClose: 3000,
        })
      }
    }
    if (data?.status === -1) {
      obj[reqKey] = {...getBatteryProfileDetails()[reqKey], ...resData}
      setDeviceLoader({profile: data?.profile, uuid: data?.deviceDetails.uuid})
      const deviceDetails = {uuid: data?.deviceDetails?.uuid, profile: data?.profile, status: -1}
      setDeviceData(deviceDetails)
      setBatteryData({...obj})
      setPdSlice({inProgress: data?.profile, loading: true})
    }
    if (data?.status === 0) {
      obj[reqKey] = {...getBatteryProfileDetails()[reqKey], ...resData}
      const deviceDetails = {
        ...data?.deviceDetails,
        profile: data?.deviceDetails.lastActiveProfile,
        enable: data?.deviceDetails.lastEnabledState,
        status: 0,
      }
      setDeviceData(deviceDetails)
      setDeviceChangeDisable({deviceChangeDisable: false})
      removeDeviceUpdate({profile: data?.profile, uuid: data?.deviceDetails?.uuid})
      clearTimer(data?.deviceDetails?.uuid)
      setBatteryData({...resData, ...obj})
      clearRequest(dispatch)
      toastType = TOAST_TYPE.ERROR
      toastMessage = i18next.t('bp252')
      if (isActivePage(PAGE_NAME.PROFILE)) {
        showToast({
          type: toastType,
          message: toastMessage,
          autoClose: 3000,
        })
      }
    }
  }
}

export function clearRequest(dispatch) {
  const batteryProfile = getBatteryProfileDetails()
  try {
    const devices = getDevices(batteryProfile)
    const setPdSlice = obj => dispatch(setPdValue(obj))
    if (!checkForLoadingState(devices)) {
      setPdSlice({inProgress: null, loading: false, requestType: null})
    }
  } catch (e) {
    console.error('[Error] clearRequest', e)
  }
}

export function getLoadingStatus(loadingValue, deviceChanges, uuid, refresh, isProfileActive, inProgress) {
  let loadingStatus = false
  const device = deviceChanges?.find(changedDevice => changedDevice.uuid === uuid)
  if (device) {
    loadingStatus = device.loading
  } else if (refresh) {
    loadingStatus = false
  } else {
    switch (loadingValue) {
      case 1:
      case 0:
        loadingStatus = (isProfileActive || inProgress) && false
        break
      case -1:
        loadingStatus = (isProfileActive || inProgress) && true
        break

      default:
        break
    }
  }
  return loadingStatus
}

export function getRetryStatus(loadingValue, deviceChanges, uuid) {
  let retryStatus = false
  const device = deviceChanges?.find(changedDevice => changedDevice.uuid === uuid)
  if (device) {
    retryStatus = false
  } else {
    retryStatus = getRetryStatusForProfile(loadingValue)
  }
  return retryStatus
}

export function getRetryStatusForProfile(loadingValue) {
  let retryStatus = false
  switch (loadingValue) {
    case 1:
    case -1:
      retryStatus = false
      break
    case 0:
      retryStatus = true
      break
    default:
      break
  }
  return retryStatus
}

// is coexistence site and iqevse charger selected
function selectedIqevseCharger(obj) {
  return isCoExistingSite() && obj.deviceType === DEVICE_TYPE.IQ_EVSE
}

function getProfileEnabledInfoDescription(profile, deviceObj) {
  let desc = ''
  switch (profile) {
    case PROFILE_TYPE.COST_SAVINGS:
      desc = isHemsSite() ? i18next.t('bp293') : i18next.t('bp227')

      if (IQGWScheduleSupportWithSavingsMode() && isHemsSite()) {
        desc = i18next.t('bp469')
      }

      if (selectedIqevseCharger(deviceObj)) {
        desc = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp470') : i18next.t('bp423')
      }

      break
    case PROFILE_TYPE.SELF_CONSUMPTION:
      desc = isHemsSite() ? i18next.t('bp291') : isPvAndCtWithEvseSite() ? i18next.t('bp229') : i18next.t('bp225')

      if (selectedIqevseCharger(deviceObj)) {
        desc = isPvAndCtWithEvseSite() ? i18next.t('bp229') : i18next.t('bp225')
      }

      break
    case PROFILE_TYPE.BACKUP_ONLY:
      desc = i18next.t('bp229')
      break
    default:
      break
  }
  return desc
}

function getProfileDisabledInfoDescription(profile, deviceObj) {
  let desc = ''
  switch (profile) {
    case PROFILE_TYPE.COST_SAVINGS:
      desc = isHemsSite() ? i18next.t('bp292') : i18next.t('bp215')

      if (selectedIqevseCharger(deviceObj)) {
        desc = i18next.t('bp424')
      }

      break
    case PROFILE_TYPE.SELF_CONSUMPTION:
      desc = isHemsSite() ? i18next.t('bp292') : i18next.t('bp215')

      if (selectedIqevseCharger(deviceObj)) {
        desc = i18next.t('bp215')
      }

      break
    case PROFILE_TYPE.BACKUP_ONLY:
      desc = isHemsSite() ? i18next.t('bp292') : i18next.t('bp215')
      break
    default:
      break
  }
  return desc
}

export function getInfoDescription(profile, deviceObj) {
  const enabledDescription = getProfileEnabledInfoDescription(profile, deviceObj)
  const disabledDescription = getProfileDisabledInfoDescription(profile, deviceObj)
  return (
    <div className="e_dialog__body__wrapper">
      <div className="e_dialog__content">
        <span className="bp__status-text--enabled">{i18next.t('bp7')}</span>: {enabledDescription}
      </div>
      <div className="e_dialog__content">
        <span className="bp__status-text--disabled">{i18next.t('bp8')}</span>: {disabledDescription}
      </div>
    </div>
  )
}

export function getErrorAlertDescription(details) {
  const desc = (
    <>
      {i18next.t('bp196')} {details.deviceName} {i18next.t('bp109')}
    </>
  )
  return (
    <div className="e_dialog__body__wrapper">
      <Icon src="infoV2" />
      <div className="e_dialog__content">{desc}</div>
    </div>
  )
}

function updateDeviceListWithChanges(deviceChanges, profile, deviceList = []) {
  const updatedDeviceList = []
  deviceList?.forEach(device => {
    let changedDevice = deviceChanges.find(change => device.uuid === change.uuid)
    if (!changedDevice) {
      let omittingValues = ['activeSchedule', 'prevSetting', 'profile', 'status', 'profileConfig']
      omittingValues =
        profile === PROFILE_TYPE.SELF_CONSUMPTION
          ? omittingValues.filter(value => value !== 'profileConfig')
          : omittingValues
      const updatedDevice = omit(device, omittingValues)
      changedDevice = {
        ...updatedDevice,
        deviceType: isCoExistingSite() ? device.deviceType : getDeviceType(),
        enable: false,
      }
    }

    // eslint-disable-next-line fp/no-mutating-methods
    updatedDeviceList.push(changedDevice)
  })
  return updatedDeviceList
}

function updateDeviceChanges(deviceChangeObj, deviceChanges, profile, dispatch) {
  const deviceUpdate = obj => dispatch(setDeviceUpdate(obj))
  deviceChangeObj?.forEach(device => {
    const changedDevice = deviceChanges.find(change => device.uuid === change.uuid)
    if (!changedDevice) {
      deviceUpdate({profile, deviceUpdates: {...device, loading: false}})
    }
  })
}

export function getDeviceListForProfileChange(activeProfile, deviceList, deviceChanges, profile, dispatch) {
  let deviceChangeObj = []
  if (activeProfile !== profile) {
    deviceChangeObj = updateDeviceListWithChanges(deviceChanges, profile, deviceList)
    updateDeviceChanges(deviceChangeObj, deviceChanges, profile, dispatch)
  } else {
    deviceChangeObj = deviceChanges
  }
  return deviceChangeObj
}

export function startTimer(id, setRefreshStatus) {
  timerObj[id] = setTimeout(() => {
    setRefreshStatus(true)
    clearTimeout(timerObj[id])
  }, 30000)
}

export function clearTimer(id) {
  clearTimeout(timerObj[id])
}

export function getMode(enable) {
  return enable ? 'full' : null
}

export function hasDeviceBehaviourChanged(deviceChanges, devices) {
  const index = devices.findIndex(device => device.status === -1)
  return deviceChanges.length > 0 && !(index > -1)
}

export function checkForLoadingState(devices) {
  const index = devices.findIndex(device => device.status === -1)
  return index > -1
}

export function getDeviceProfileTranslation(deviceProfile) {
  const profileTranslation = {
    backup_only: 'bp253',
    cost_savings: IQGWScheduleSupportWithSavingsMode() ? 'bp467' : 'bp231',
    'self-consumption': 'bp254',
  }
  return profileTranslation[deviceProfile]
}

export function getDeviceProfileEnabledTranslation(enabled) {
  return enabled ? 'bp7' : 'bp8'
}

export function getScheduleTranslation(scheduleName) {
  const scheduleTranslation = {
    custom: 'bp165',
    'off-peak': 'bp166',
    Off: 'bp167',
    none: 'bp234',
    always: 'bp218',
    Disabled: 'bp8',
  }
  return scheduleTranslation[scheduleName]
}

export function getModeTranslation(modeName) {
  const modeTranslation = {
    GREEN: 'bp487',
    SMART: 'bp488',
    SCHEDULE: 'bp490',
    MANUAL: 'bp491',
  }
  return modeTranslation[modeName]
}
