/* eslint-disable fp/no-mutation */

import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import _ from 'lodash'
import i18next from 'i18next'

import {batteryProfileSelector, setBpDetailsValue} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {
  ACTION_TYPE,
  COMPONENT_SHORT_HAND,
  DIALOG_TYPE,
  PENDING_REQ_KEY,
  PROFILE_TYPE,
  TOAST_TYPE,
  TOGGLE_SWITCH_STATUS,
} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {isDemoUserBlocked, isUserHasAccess} from 'lib/utils/userDetails'
import {isNEM3Site, isNem3TariffSupported, showDischargeToGrid} from 'lib/utils/siteDetails'
import {minutesToString, stringToMinutes} from 'lib/utils/timeDetails'
import {HTTPS_API_STATUS, HTTPS_REQUEST_TYPE} from 'lib/services/httpRequest'
import {cancelBatteryRequest, setBatteryDetails} from 'lib/services/batteryDetailsAPI'
import {handleError} from 'lib/common'
import {ToggleSwitch, toggleStatusInfo} from '../../atoms/ToggleSwitch'
import {DischargeToGridSchedule} from './dbtgSchedule'
import {IntractiveButton} from '../../atoms/IntractiveButton'
import {
  isAnyRequestPendingForDischargeBatteryToGrid,
  isAnyRequestPendingForProfile,
  isDischargeToGridRequestPending,
  isRequestPendingForDischargeBatteryToGridByRequestType,
} from '../../organisms/reqPending'
import {setDbtgValue} from './dbtgSlice'
import './styles.scss'

import {isWeb, omit} from '../../../../utils/utility'
import {
  getCardDetails,
  getDbtgToggleStatus,
  getDescription,
  getDisclaimerPopup,
  getInfoDescription,
  getNEM3ExpOnlyTariffAlertDetails,
  getSystemProfile,
  getTimeErrorDbtg,
  isDischargeToGridLocked,
  isNEM3SelfConsExpOnlySite,
  noScheduleForNEM3SelfConsExpOnlySite,
  restrictUserToPerformDTGAction,
  showInfoIcon,
} from './dbtgCore'
import {showToast} from '../../atoms/Toast'
import {getRequestType} from '../../organisms/mqttRequest'
import {init, updateBatterySliceValues} from '../../organisms/BatteryDetails/bdCore'

/* *********************** *********************** *********************** *
 *  Discharge Battery To Grid
 * *********************** *********************** *********************** */

export const DischargeBatteryToGrid = () => {
  const {loading, toggleStatus, scheduleSupported, startTime, endTime, isDisclaimerSelected, isDbtgInfoChanged} =
    useSelector(state => state.components.dischargeBatteryToGrid)
  const isCbfgInfoChanged = useSelector(state => state.components.chargeBatteryFromGrid.isCbfgInfoChanged)
  const isCbfgLoading = useSelector(state => state.components.chargeBatteryFromGrid.loading)
  const batteryProfile = useSelector(batteryProfileSelector)
  const isBatteryShutdownLoading = useSelector(state => state.components.batteryShutdown.loading)
  const isBsInfoChanged = useSelector(state => state.components.batteryShutdown.isBsInfoChanged)
  const {details} = batteryProfile

  const dbtgTitle = i18next.t('bp397')
  const dbtgDescription = getDescription(toggleStatus, details)

  const dbtgDetails = getCardDetails(details)
  const dbtgButtonLabel = dbtgDetails.buttonLabel
  const dbtgButtonActionType = dbtgDetails.buttonActionType

  // Set null when optimise schedule is not available for NEM3SelfConsExpOnlySite
  const dischargeBeginTimeTxt = noScheduleForNEM3SelfConsExpOnlySite(details)
    ? null
    : minutesToString(details?.dtgControl?.startTime)

  const dischargeEndTimeTxt = noScheduleForNEM3SelfConsExpOnlySite(details)
    ? null
    : minutesToString(details?.dtgControl?.endTime)

  const lockDTGCard = restrictUserToPerformDTGAction({details, isCbfgLoading, loading})

  const dispatch = useDispatch()
  const setToggleStatus = val => dispatch(setDbtgValue({toggleStatus: val}))
  const setScheduleSupported = val => dispatch(setDbtgValue({scheduleSupported: val}))
  const setRequestType = val => dispatch(setDbtgValue({requestType: val}))
  const setLoading = val => dispatch(setDbtgValue({loading: val}))
  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const setBatteryData = obj => dispatch(setBpDetailsValue(obj))
  const setIsDisclaimerSelected = val => dispatch(setDbtgValue({isDisclaimerSelected: val}))
  const setDbtgInfoChanged = val => dispatch(setDbtgValue({isDbtgInfoChanged: val}))
  const resetDbtgData = obj => dispatch(setDbtgValue({...obj}))

  // useEffect on mount
  useEffect(() => {
    updateBatterySliceValues({source: COMPONENT_SHORT_HAND.DISCHARGE_BATTERY_TO_GRID, dispatch, data: details})
    setLoading(isRequestPendingForDischargeBatteryToGridByRequestType(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT))
  }, [batteryProfile.loading])

  useEffect(() => {
    // reset dbtg data if user updating CFG / Battery Shutdown level card
    if (isCbfgInfoChanged || isBsInfoChanged) {
      resetDbtgData({
        isDbtgInfoChanged: false,
        toggleStatus: details?.dtgControl?.enabled ? TOGGLE_SWITCH_STATUS.ON : TOGGLE_SWITCH_STATUS.OFF,
        startTime: {
          formatted12: noScheduleForNEM3SelfConsExpOnlySite(details)
            ? null
            : minutesToString(details?.dtgControl?.startTime),
        },
        endTime: {
          formatted12: noScheduleForNEM3SelfConsExpOnlySite(details)
            ? null
            : minutesToString(details?.dtgControl?.endTime),
        },
        scheduleSupported: !!details?.dtgControl?.scheduleSupported,
      })
    }
  }, [isCbfgInfoChanged, isBsInfoChanged])

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  const getStatusInfoDetails = () => {
    const dbtgInfoDescription = getInfoDescription(details)
    const obj = {
      className: 'dbtg__info-popup',
      type: isWeb() ? DIALOG_TYPE.CENTER : DIALOG_TYPE.BOTTOM,
      showDialog: true,
      showCloseIcon: true,
      title: dbtgTitle,
      content: dbtgInfoDescription,
      buttons: null,
      onClickCloseIcon: closeThisDialog,
    }
    return obj
  }

  // On Click Info Icon
  const onClickInfo = () => {
    const obj = getStatusInfoDetails()
    showDialog(obj)
  }

  function showNEM3TariffPopup() {
    const obj = getNEM3ExpOnlyTariffAlertDetails(closeThisDialog)
    showDialog(obj)
  }

  // On Action Button Click
  const onActionButtonClick = obj => {
    // Check User Access
    if (!isUserHasAccess()) return

    if (obj.type === ACTION_TYPE.SAVE) save()
    else if (obj.type === ACTION_TYPE.CANCEL) cancel()
  }

  // Handle Toggle Change
  const handleToggleChange = value => {
    const dischargeBatteryToGrid = getDbtgToggleStatus(details)
    if (isDemoUserBlocked()) return
    if (
      restrictUserToPerformDTGAction({
        details,
        isCbfgLoading,
        loading,
        showDialog,
        closeThisDialog,
        isBatteryShutdownLoading,
        showToastMsg: true,
      })
    ) {
      return
    }

    if (
      isNEM3SelfConsExpOnlySite(details) &&
      !isNem3TariffSupported() &&
      dischargeBatteryToGrid === TOGGLE_SWITCH_STATUS.OFF &&
      value === TOGGLE_SWITCH_STATUS.ON
    ) {
      showNEM3TariffPopup()
      return
    }

    if (value === 'on') {
      if (details?.profile === PROFILE_TYPE.SELF_CONSUMPTION) setScheduleSupported(true)
      !isNEM3Site() && dischargeBatteryToGrid !== TOGGLE_SWITCH_STATUS.ON
        ? showDisclaimerPopup()
        : setToggleStatus(value)
    } else {
      if (details?.profile === PROFILE_TYPE.SELF_CONSUMPTION) setScheduleSupported(false)
      setToggleStatus(value)
    }
  }

  /* *********************** *********************** *********************** *
   * Functions
   * *********************** *********************** *********************** */

  const cancelDisclaimer = () => {
    closeThisDialog()
    setIsDisclaimerSelected(false)
    setToggleStatus(TOGGLE_SWITCH_STATUS.OFF)
  }

  const enableDisclaimer = async checked => {
    if (checked) {
      closeThisDialog()
      setToggleStatus(TOGGLE_SWITCH_STATUS.ON)
    }
    setIsDisclaimerSelected(false)
  }

  const onChangeDisclaimerCheckbox = checked => {
    setIsDisclaimerSelected(checked)
    const obj = getDisclaimerPopupDetails(checked)
    const [, enabled] = obj.buttons
    enabled.disabled = !checked

    dispatch(setDialogValue(obj))
  }

  const getDisclaimerPopupDetails = checked => {
    const disclaimerDescription = getDisclaimerPopup({checked, onChangeDisclaimerCheckbox})
    const obj = {
      className: 'dbtg__popup__wrapper',
      type: DIALOG_TYPE.CENTER,
      showDialog: true,
      showCloseIcon: false,
      title: i18next.t('bp397'),
      content: disclaimerDescription,
      onClickCloseIcon: closeThisDialog,
    }

    obj.buttons = [
      {
        value: i18next.t('bp99'),
        action: cancelDisclaimer,
        disabled: false,
        className: 'bp__ab--mobile-view',
      },
      {
        value: i18next.t('bp368'),
        action: () => enableDisclaimer(checked),
        disabled: !checked,
        className: 'bp__ab--mobile-view bp__nobg-color',
      },
    ]

    return obj
  }

  // ShowDisclaimerPopup
  const showDisclaimerPopup = () => {
    const obj = getDisclaimerPopupDetails(isDisclaimerSelected)
    showDialog(obj)
  }

  // isInfoChanged
  function isInfoChanged() {
    const dischargeBatteryToGrid = getDbtgToggleStatus(details)
    const timeError = getTimeErrorDbtg(startTime, endTime)

    let isValid = false

    if (loading) isValid = true
    if (isAnyRequestPendingForDischargeBatteryToGrid(details)) isValid = true

    if (toggleStatus === TOGGLE_SWITCH_STATUS.OFF) {
      if (dischargeBatteryToGrid !== toggleStatus) isValid = true
    } else if (toggleStatus === TOGGLE_SWITCH_STATUS.ON) {
      if (timeError) {
        isValid = false
      } else if (
        dischargeBatteryToGrid !== toggleStatus ||
        dischargeBeginTimeTxt !== startTime.formatted12 ||
        dischargeEndTimeTxt !== endTime.formatted12
      ) {
        isValid = true
      }
    }
    validateDbtgInfoChanged()
    return isValid
  }

  function validateDbtgInfoChanged() {
    if (isDbtgInfoChanged) return

    const dischargeBatteryToGrid = getDbtgToggleStatus(details)
    if (
      dischargeBatteryToGrid !== toggleStatus ||
      dischargeBeginTimeTxt !== startTime.formatted12 ||
      dischargeEndTimeTxt !== endTime.formatted12
    ) {
      setDbtgInfoChanged(true)
    }
  }

  // Save CTG
  async function save() {
    if (!_.isBoolean(details?.dtgControl?.enabled)) {
      showToast({
        type: TOAST_TYPE.ERROR,
        message: i18next.t('bp68'),
      })
      return
    }

    const obj = {
      dtgControl: {
        enabled: toggleStatus === TOGGLE_SWITCH_STATUS.ON,
      },
    }

    if (toggleStatus === TOGGLE_SWITCH_STATUS.ON) {
      if (!noScheduleForNEM3SelfConsExpOnlySite(details)) {
        obj.dtgControl.startTime = stringToMinutes(startTime.formatted12)
        obj.dtgControl.endTime = stringToMinutes(endTime.formatted12)
      }
      obj.dtgControl.scheduleSupported = scheduleSupported
    }

    setRequestType(
      getRequestType(HTTPS_REQUEST_TYPE.MQTT, {details}) ? HTTPS_REQUEST_TYPE.MQTT : HTTPS_REQUEST_TYPE.API
    )
    setLoading(true) // When calling the save DTG method, set loading flag to true.

    // Set Battery Details
    try {
      const resProfile = await setBatteryDetails(obj)
      if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
        console.debug('DTG Request submitted successfully')
      } else {
        throw new Error('Error')
      }
    } catch (error) {
      console.error('[Error] Discharge Battery to Grid - Save', error)

      setRequestType(null)
      setLoading(false) // If you receive an error when updating DTG, set loading false.

      showToast({
        type: TOAST_TYPE.ERROR,
        message: i18next.t('bp68'),
        autoClose: 3000,
      })
    }
  }

  // Cancel CTG
  async function cancel() {
    // cancel CTG
    const obj = {}
    obj.requestedConfig = omit(details.requestedConfig, ['dtgControl'])

    setRequestType(HTTPS_REQUEST_TYPE.API)
    setLoading(true)

    // Cancel Battery Request
    try {
      const resProfile = await cancelBatteryRequest({type: 'dischargeToGrid'})
      if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
        showToast({
          type: TOAST_TYPE.SUCCESS,
          message: i18next.t('bp76'),
          autoClose: 3000,
        })

        setBatteryData(obj)
        setToggleStatus(getDbtgToggleStatus({...details, ...obj}))
        setLoading(false)
      } else {
        throw new Error('Error')
      }
    } catch (e) {
      const err = e.error || e
      setLoading(false)
      handleError({error: e, showToast, dispatch})

      if (err.response.status === 409) {
        init({dispatch})
      }
    } finally {
      setRequestType(null)
    }
  }

  /* *********************** *********************** *********************** *
   * Render Function
   * *********************** *********************** *********************** */

  // Render Toggle
  const renderToggle = () => {
    const tStatus = toggleStatus
    const tLoading = loading || isAnyRequestPendingForDischargeBatteryToGrid(details)
    const disabled = tLoading && !isAnyRequestPendingForProfile(details)
    const lockDTGToggle = (tLoading || lockDTGCard) && toggleStatus === TOGGLE_SWITCH_STATUS.ON

    return (
      <div className="dbtg__status__toggle-container">
        {isWeb() && (
          <div className={`dbtg__status-text ${lockDTGToggle && 'dbtg__profile--savings'}`}>
            {toggleStatusInfo({loading: tLoading, status: toggleStatus})}
          </div>
        )}
        <ToggleSwitch
          key="dbtg-toggle"
          id="dbtg-toggle"
          status={tStatus}
          disabled={disabled}
          className={`${lockDTGToggle && 'lock-dtg-toggle'}`}
          handleChange={handleToggleChange}
        />
      </div>
    )
  }

  const renderStatusInfo = () => {
    const dbtgSavingsProfile =
      isDischargeToGridLocked(details) && getSystemProfile(details) === PROFILE_TYPE.COST_SAVINGS

    const tLoading =
      loading ||
      isDischargeToGridRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
      isDischargeToGridRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
    return (
      <>
        <div className={`dbtg__status-text ${dbtgSavingsProfile && 'dbtg__profile--savings'}`}>
          {toggleStatusInfo({loading: tLoading, status: toggleStatus})}
        </div>

        {showInfoIcon(details) && (
          <div className="dbtg__info" onClick={onClickInfo} onKeyDown={() => {}} role="button" tabIndex="0">
            <Icon src="info" />
          </div>
        )}
      </>
    )
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  // Hide Discharge to Grid
  if (!showDischargeToGrid()) return false

  return (
    <section id="bp-discharge-battery-to-grid" className="bp__card-view">
      <div className="bp__card-view__wrapper">
        <div className="dbtg__title-toggle">
          <div className="bp__card-view--title">
            {dbtgTitle}
            {isWeb() && showInfoIcon(details) && (
              <span onClick={onClickInfo} onKeyDown={onClickInfo} role="button" tabIndex={0}>
                <Icon src="info" />
              </span>
            )}
          </div>
          <div className="dbtg__toggle">{renderToggle()}</div>
        </div>

        {!isWeb() && <div className="dbtg__status-info">{renderStatusInfo()}</div>}
        {dbtgDescription && <div className="bp__card-view--desc">{dbtgDescription}</div>}

        {scheduleSupported && (
          <DischargeToGridSchedule
            status={toggleStatus}
            lockDTGCard={lockDTGCard}
            showDialog={showDialog}
            closeThisDialog={closeThisDialog}
          />
        )}
      </div>

      {isInfoChanged() && (
        <IntractiveButton
          className="dbtg__button ib__btn"
          type={dbtgButtonActionType}
          label={dbtgButtonLabel}
          loading={loading}
          disabled={!isInfoChanged()}
          onClick={onActionButtonClick}
        />
      )}
    </section>
  )
}
