import {assign} from 'lodash'
import envDetails from './envDetails'

const e = require('e_modules')

// Domains
const productionDomains = {
  GRID_SERVICES: 'https://gs.enphaseenergy.com',
  HEMS_INTEGRATION: 'https://hems-integration.enphaseenergy.com',
}

const blueDomains = {}

const preprodDomains = {
  GRID_SERVICES: 'https://gs-preprod.enphaseenergy.com',
  HEMS_INTEGRATION: 'https://hems-integration-preprod.enphaseenergy.com',
}

const releaseDomains = {
  GRID_SERVICES: 'https://gs-rel.qa-enphaseenergy.com',
  HEMS_INTEGRATION: 'https://hems-integration-rel.enphaseenergy.com',
}

const qaBlueDomains = {}

const qaDomains = {
  GRID_SERVICES: 'https://gs-stg.qa-enphaseenergy.com',
  HEMS_INTEGRATION: 'https://hems-integration-stg.enphaseenergy.com',
  QA2_ENV: 'https://qa2.enphaseenergy.com',
}

const integrationDomains = {}

const developmentDomains = {
  GRID_SERVICES: 'https://gs-dev.qa-enphaseenergy.com',
  HEMS_INTEGRATION: 'https://hems-integration-dev.qa-enphaseenergy.com',
}

const localDomains = {}

const DOMAIN = {
  [e.envDetails.constants.PRODUCTION]: productionDomains,
  [e.envDetails.constants.BLUE]: blueDomains,
  [e.envDetails.constants.PREPROD]: preprodDomains,
  [e.envDetails.constants.RELEASE]: releaseDomains,
  [e.envDetails.constants.QA_BLUE]: qaBlueDomains,
  [e.envDetails.constants.QA]: qaDomains,
  [e.envDetails.constants.INTEGRATION]: integrationDomains,
  [e.envDetails.constants.DEVELOPMENT]: developmentDomains,
  [e.envDetails.constants.LOCAL]: localDomains,
}

function getAppOrigin(key) {
  const env = envDetails.getEnv()
  const DEFAULT = DOMAIN[e.envDetails.constants.PRODUCTION]
  const envBasedDomains = DOMAIN[env] || {}

  if (env === e.envDetails.constants.LOCAL) {
    return envDetails.getOrigin()
  }

  if (env === e.envDetails.constants.PRODUCTION) {
    return DEFAULT[key]
  }

  return assign({}, DEFAULT, envBasedDomains)[key]
}

const appDomains = {
  DOMAIN,
  getAppOrigin,
}

export default appDomains
