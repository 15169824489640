/* eslint-disable fp/no-mutation */

import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {PAGE_NAME} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {isEnhoWeb, setLocale} from 'lib/utils/utility'
import {showHeader} from 'lib/utils/pageDetails'
import {Dialog} from '../../atoms/Dialog'
import ShowErrorDetails from '../../atoms/Error/showErrorDetails'
import Loader from '../../atoms/Loader'
import {Toast} from '../../atoms/Toast'
// import {BatteryReserve} from '../../molecules/BatteryReserve'
import Header from '../../atoms/Header'
import {BatteryShutdown} from '../../molecules/BatteryShutdown'
import {BatteryMode} from '../../molecules/BatteryMode'
import {ChargeBatteryForEv} from '../../molecules/ChargeBatteryForEv'
import {ChargeBatteryFromGrid} from '../../molecules/ChargeBatteryFromGrid'
import {DischargeBatteryToGrid} from '../../molecules/DischargeBatteryToGrid'
import {closeWebView} from '../bpCommon'
import {init} from './bdCore'
import 'react-toastify/dist/ReactToastify.min.css'
import './styles.scss'

export const BatteryDetails = () => {
  const loader = useSelector(state => state.components.loader)
  const dialogDetails = useSelector(state => state.components.dialogDetails)
  const batteryProfile = useSelector(state => state.system.batteryProfile)

  const dispatch = useDispatch()

  // useEffect on mount
  useEffect(() => {
    setLocale()
    init({dispatch})
    document.title = i18next.t('bp191')
  }, [])

  return (
    // Battery Details
    <div id="bp-page-battery-details" className="bp__page">
      {showHeader() && (
        <Header
          id={PAGE_NAME.BATTERY}
          title={i18next.t('bp191')}
          actionTitle={isEnhoWeb() ? <Icon src="close" /> : null}
          onClickAction={closeWebView}
        />
      )}

      {!(batteryProfile.apiError || batteryProfile.invalidAccess) && (
        <div className="bp__page--wrapper">
          <BatteryMode />
          <ChargeBatteryFromGrid />
          <DischargeBatteryToGrid />
          {/* <BatteryReserve /> */}
          <BatteryShutdown />
          <ChargeBatteryForEv />

          {batteryProfile.noActiveFeatureForBattery && <div className="bp__text--center">{i18next.t('bp111')}</div>}
        </div>
      )}

      {batteryProfile.apiError ? (
        <ShowErrorDetails />
      ) : (
        batteryProfile.invalidAccess && <ShowErrorDetails text={i18next.t('bp194')} subText={i18next.t('bp195')} />
      )}

      <Loader {...loader} />
      <Toast />
      <Dialog
        id={dialogDetails.id}
        className={`e_dialog__battery ${dialogDetails.className}`}
        ariaLabel={dialogDetails.ariaLabel}
        type={dialogDetails.type}
        showCloseIcon={dialogDetails.showCloseIcon}
        showDialog={dialogDetails.showDialog}
        title={dialogDetails.title}
        content={dialogDetails.content}
        buttons={dialogDetails.buttons}
        closeOnClickOverlay={dialogDetails.closeOnClickOverlay}
        onClickCloseIcon={dialogDetails.onClickCloseIcon}
      />
    </div>
  )
}

BatteryDetails.propTypes = {}

BatteryDetails.defaultProps = {}
