/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-mutating-methods */
import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import {Slide, toast, ToastContainer} from 'react-toastify'
import {updateSpecificKeyValue} from 'lib/utils'
import {KEY, TOAST_POSITION, TOAST_TYPE} from '../../../../utils/constants'
import Icon from '../../../../utils/icon'
import './styles.scss'

/*
  Ref: https://fkhadra.github.io/react-toastify/introduction
*/

const DEFAULT_VALUES = {
  theme: 'light',
  icon: obj => renderIcon(obj),
  autoClose: 3000,
  clearOthers: true,
  closeOnClick: true,
  closeButton: false,
  draggable: false,
  draggablePercent: 60,
  position: TOAST_POSITION.TOP_CENTER,
  pauseOnFocusLoss: false,
  transition: Slide,
  rtl: false,
  delay: 0,
  limit: 1,
  hideProgressBar: true,
  newestOnTop: true,
  pauseOnHover: false,
  containerId: 'toast-default',
}

function getDetails(obj) {
  const classNames = [obj?.className, 'bp__toast']
  let iconSrc = null

  switch (obj?.type) {
    case TOAST_TYPE.SUCCESS:
      classNames.push('bp__toast--success')
      iconSrc = 'toastSuccess'
      break
    case TOAST_TYPE.ERROR:
      classNames.push('bp__toast--error')
      iconSrc = 'toastError'
      break
    case TOAST_TYPE.INFO:
      classNames.push('bp__toast--info')
      iconSrc = 'toastInfo'
      break
    default:
      classNames.push('bp__toast--info')
  }

  return {
    className: classNames.join(' '),
    iconSrc,
  }
}

function renderIcon(obj) {
  const {iconSrc} = getDetails(obj)
  return <Icon src={iconSrc} width={18} height={18} />
}

export const Toast = () => {
  function getRender() {
    const domId = document.getElementById(KEY.MODULE_NAME) || document.body
    const modal = <ToastContainer {...DEFAULT_VALUES} className={getDetails().className} />

    if (domId) {
      return ReactDOM.createPortal(modal, domId)
    }

    return null
  }

  return getRender()
}

export function closeToast(id) {
  toast.dismiss(id)
}

export function showToast(obj) {
  const {type, message} = obj
  const mustObj = {
    className: getDetails(obj).className,
    onClose: obj.closeToast,
  }

  let toastObj = updateSpecificKeyValue({...obj, ...mustObj}, DEFAULT_VALUES)
  if (toastObj.clearOthers) {
    toast.dismiss()
  }

  toastObj = _.omit(toastObj, ['clearOthers'])
  if (type && message) {
    toast[type](message, toastObj)
  } else if (message) {
    toast(message, toastObj)
  }
}

Toast.propTypes = {}

Toast.defaultProps = {}

export default Toast
