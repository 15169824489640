export const MY_ENLIGHTEN = 'my_enlighten'
export const SUPPORT_ENTREZ = 'support-entrez'
export const GRID_SERVICES = 'grid_services'

export const KEY = {
  ORIGIN: 'origin',
  SESSION_ID: '_enlighten_4_session',
  SESSION_KEY: 'session',
  E_AUTH_TOKEN: 'e-auth-token',
  MODULE_NAME: 'battery-profile',
  THEME: 'theme',
}

export const SUPPORTED_LOCALE = {
  ES: 'es', // Spanish
  EN: 'en', // English
  EN_AU: 'en-AU', // English (Australia)
  DE: 'de', // German
  NL: 'nl', // Dutch
  IT: 'it', // Italian
  FR: 'fr', // French
  PL: 'pl', // Polish
  PT: 'pt', // Portuguese
}

export const COUNTRY_CODE = {
  US: 'US',
  AU: 'AU',
  NZ: 'NZ',
  MX: 'MX',
  CA: 'CA',
  FR: 'FR',
  BE: 'BE',
  NL: 'NL',
  DE: 'DE',
  ES: 'ES',
  IT: 'IT',
}

export const REGION = {
  NA: 'NA',
}

export const SUPPORTED_THEME = {
  LIGHT: 'light',
  DARK: 'dark',
  ORANGE: 'orange',
}

export const STATUS = {
  LOADING: 'loading',
  ENABLED: 'enabled',
  DISABLED: 'disabled',
}

export const ACTION_TYPE = {
  NEXT: 'next',
  SAVE: 'save',
  UPDATE: 'update',
  TRY_AGAIN: 'try-again',
  CANCEL: 'cancel',
  EDIT: 'edit',
  RESET: 'reset',
  DISABLED: 'disabled',
}

export const MOMENT_JS_FORMAT = {
  TIME_12H_AM_PM: 'hh:mm a',
  DATE_MMM_DD_TIME_12H_AM_PM: 'MMM DD, hh:mm A',
}

export const REQUEST_TYPE = {
  BP_COMP_MODULE: 'bp-comp-module',
}

export const LINKS = {
  CUSTOMIZE_NOTIFICATION:
    'https://community.enphase.com/s/article/What-are-different-notification-preferences-and-how-to-customize-for-your-system',
}

export const LIVE_STATUS = {
  CONNECTED: 'connected',
  NOTCONNECTED: 'notConnected',
  UNKNOWN: 'unknown',
}

export const IS_DESKTOP = () => window.innerWidth >= 1024

// Profile Type
export const PROFILE_TYPE = {
  SELF_CONSUMPTION: 'self-consumption',
  COST_SAVINGS: 'cost_savings',
  BACKUP_ONLY: 'backup_only',
}

// Profile Type for IQGWScheduleSupportedSites
export const PROFILE_TYPE_NEM3 = {
  COST_SAVINGS: PROFILE_TYPE.COST_SAVINGS,
  SELF_CONSUMPTION: PROFILE_TYPE.SELF_CONSUMPTION,
  BACKUP_ONLY: PROFILE_TYPE.BACKUP_ONLY,
}

export const TUTORIAL_PROFILE_TYPE = {
  AI_SAVINGS: 'AI-Optimisation',
  SELF_CONSUMPTION: PROFILE_TYPE.SELF_CONSUMPTION,
  COST_SAVINGS: PROFILE_TYPE.COST_SAVINGS,
  BACKUP_ONLY: PROFILE_TYPE.BACKUP_ONLY,
}

export const YT_VIDEO_IDS = {
  TUTORIAL_NEM3: 'AG8lDZ7ABzE',
}

// Profile Status
export const PROFILE_STATUS = {
  ACTIVE: 'active',
  PROCESSING: 'processing',
  PENDING: 'pending',
}

// After Peak :: Operation Mode Subtype Status Type
export const OMST_STATUS = {
  PRIORITIZE_ENERGY: 'prioritize-energy',
}

// Storm Guard :: Status Type
export const STORM_GUARD_STATUS = {
  ACTIVE: 'active',
  ENABLED: 'enabled',
  DISABLED: 'disabled',
}

// Storm Alert :: Status Type
export const STORM_ALERT_STATUS = {
  ACTIVE: 'active',
  OPTED_OUT: 'opted-out',
  OPTED_IN: 'opted-in',
}

// Request Pending Types
export const PENDING_REQ_KEY = {
  REQUESTED_CONFIG_MQTT: 'requestedConfigMqtt',
  REQUESTED_CONFIG: 'requestedConfig',
}

// Toggle Switch :: Status Type
export const TOGGLE_SWITCH_STATUS = {
  LOADING: 'loading',
  ON: 'on',
  OFF: 'off',
}

// Toggle Switch :: Toast Type
export const TOAST_TYPE = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  DEFAULT: 'default',
}

// Toggle Switch :: Toast Position
export const TOAST_POSITION = {
  TOP_RIGHT: 'top-right',
  TOP_CENTER: 'top-center',
  TOP_LEFT: 'top-left',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_LEFT: 'bottom-left',
}

// Page Name
export const PAGE_NAME = {
  PROFILE: 'profile',
  BATTERY: 'battery',
  EDIT_PROFILE: 'edit-profile',
  STORM_GUARD: 'storm-guard',
  EV_SCHEDULE: 'ev-schedule',
}

// Screen type
export const SCREEN_TYPE = {
  WEB: 'web',
  MOBILE: 'mobile',
}

// Source type
export const SOURCE_TYPE = {
  ITK: 'itk',
  ENHO: 'enho',
}

// OS type
export const OS_TYPE = {
  IOS: 'ios',
  ANDROID: 'android',
  BROWSER: 'browser',
}

// Dialog type
export const DIALOG_TYPE = {
  TOP: 'top',
  BOTTOM: 'bottom',
  CENTER: 'center',
}

export const TUTORIAL_TYPE = {
  SUGGEST_PROFILE: 'findRightProfile',
  CHOOSE_PROFILE: 'chooseRightProfile',
}

// Device type
export const DEVICE_TYPE = {
  IQ_EVSE: 'iqEvse',
  THIRD_PARTY_EVSE: 'thirdPartyEvse',
}

// Battery Grid Mode
export const BATTERY_GRID_MODE = {
  IMPORT_ONLY: 'ImportOnly',
  EXPORT_ONLY: 'ExportOnly',
  IMPORT_EXPORT: 'ImportExport',
  NO_GRID_INTERACTION: 'NoInteraction',
}

// Schedule error status
export const SCHEDULE_ERROR_STATUS = {
  THROTTLED: 'THROTTLED',
  MISCONFIGURED_SITE: 'MISCONFIGURED-SITE',
  INTERNAL_ERROR: 'INTERNAL-ERROR',
  MISSING_FORECASTS: 'MISSING-FORECASTS',
  MISSING_TARIFFS: 'MISSING-TARIFFS',
  MISSING_DATA: 'MISSING-DATA',
}

// MQTT payload key
export const MQTT_PAYLOAD = {
  STATUS_CODE: 'statusCode',
}

// Site status key
export const SITE_STATUS = {
  WARNING: 'warning',
  COMM: 'comm',
}

// Disclaimer type
export const DISCLAIMER_TYPE = {
  KEY: 'disclaimer-type',
  ITC: 'itc',
  GIC: 'gic',
  DT_SAVINGS: 'dt-savings',
}

// Component short hand
export const COMPONENT_SHORT_HAND = {
  CHARGE_BATTERY_FROM_GRID: 'cbfg',
  DISCHARGE_BATTERY_TO_GRID: 'dbtg',
}

export const HEMS_ACTIVATION_STATUS = 'hems-activation-status'

export const DOMAIN = {
  HEMS_INTEGRATION: 'HEMS_INTEGRATION',
}
