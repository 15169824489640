/* eslint-disable fp/no-mutation */

import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import Icon from 'lib/utils/icon'
import {batteryProfileSelector} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {setLoaderValue} from 'lib/store/slices/loaderSlice'
import Analytics from 'lib/utils/analytics'
import {DIALOG_TYPE, OS_TYPE, SOURCE_TYPE, YT_VIDEO_IDS} from 'lib/utils/constants'
import {getProfileTutorialVideoURL, isNEM3Site, siteHasMultipleProfiles} from 'lib/utils/siteDetails'
import {isOSType, isSource, isWeb} from 'lib/utils/utility'
import {YtVideoPlayer} from '../../atoms/YtVideoPlayer'
import {setPdValue} from '../../organisms/ProfileDetails/pdSlice'
import {selectTutorialDetails} from '../../organisms/hspCommon'
import {setBrValue} from '../BatteryReserve/brSlice'
import './styles.scss'

export const SelectProfileHelp = () => {
  const sphTitle = siteHasMultipleProfiles() ? i18next.t('bp14') : i18next.t('bp145')
  const batteryReserve = useSelector(state => state.components.batteryReserve)
  const dispatch = useDispatch()
  const closeThisDialog = () => dispatch(closeDialog())
  const showLoader = obj => dispatch(setLoaderValue({...obj}))
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const setBrSlice = obj => dispatch(setBrValue({...obj}))
  const setPdSlice = obj => dispatch(setPdValue({...obj}))
  const {details} = useSelector(batteryProfileSelector)
  const [, setShowNEM3TutorialVideo] = useState(false)
  const AppTutorialVideoId = getProfileTutorialVideoURL()

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  const selectTutorial = () => {
    if (isNEM3Site() || AppTutorialVideoId) {
      showLoader({className: 'bp__loader--no-bg-loader', show: true, noBg: true})

      const openAsExternalURL = isSource(SOURCE_TYPE.ITK)
      Analytics.sendClickEvent('Profile_Help_NEM', 'Profile')
      setShowNEM3TutorialVideo(true)

      const openInFullScreenMode = isOSType(OS_TYPE.IOS) || isOSType(OS_TYPE.ANDROID)
      const onOpenMinimiseView = isOSType(OS_TYPE.ANDROID)
      const onCloseMinimiseView = isWeb() || isOSType(OS_TYPE.ANDROID)
      const obj = {
        id: 'bp-nem-video-dialog',
        className: `bp__video-dialog ${openInFullScreenMode && !onOpenMinimiseView ? 'bp__video-dialog--hidden' : ''}`,
        type: DIALOG_TYPE.CENTER,
        showDialog: true,
        showCloseIcon: true,
        title: sphTitle,
        buttons: [],
        closeOnClickOverlay: !isWeb(),
        onClickCloseIcon: closeThisDialog,
      }

      const content = (
        <YtVideoPlayer
          id={obj.id}
          className="bp-select-profile-video"
          videoId={AppTutorialVideoId || YT_VIDEO_IDS.TUTORIAL_NEM3}
          videoContainerId="yt-video__container"
          bannerId="yt-video__banner"
          allowFullScreen
          openAsExternalURL={openAsExternalURL}
          openInFullScreenMode={openInFullScreenMode}
          onCloseMinimiseView={onCloseMinimiseView}
          onCloseCallback={() => {
            setShowNEM3TutorialVideo(false)
            closeThisDialog()
          }}
          showLoader={showLoader}
        />
      )

      obj.content = content
      showDialog(obj)
    } else {
      Analytics.sendClickEvent('Profile_Help', 'Profile')
      selectTutorialDetails({
        showDialog,
        closeThisDialog,
        details,
        setBrSlice,
        batteryReserve,
        setPdSlice,
        screen: 1,
        tutorialObj: {firstVal: 1, secondVal: 2},
      })
    }
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  return (
    // Help me Select a Profile
    <section id="bp-select-profile-help" className="bp__card-view">
      <div className="bp__card-view__wrapper">
        <div className="sph__title-wrapper" onClick={selectTutorial} role="document" onKeyDown={() => {}} tabIndex="0">
          <div className="sph__title-icon">
            <div className="sph__icon">
              {isNEM3Site() || AppTutorialVideoId ? <Icon src="tutorialVideoIcon" /> : <Icon src="tutorial" />}
            </div>
            <div className="sph__title">{sphTitle}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

SelectProfileHelp.propTypes = {}

SelectProfileHelp.defaultProps = {}
