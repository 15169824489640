/* eslint-disable fp/no-mutation */
import React from 'react'
import i18next from 'i18next'
import {isWeb, isSource, toKebabCase} from 'lib/utils/utility'
import {popupFromIframe} from 'lib/common'
import {DIALOG_TYPE, SOURCE_TYPE, TUTORIAL_TYPE} from 'lib/utils/constants'
import {systemWithEnchargeAndGridAgnostic, siteHasMultipleProfiles, showSavingsMode} from 'lib/utils/siteDetails'
import {renderItktutorial} from '../molecules/Itktutorial'
import {renderSelectionContent} from '../molecules/SelectProfileHelp/sphCore'
import {getLastSetReserveObj} from '../molecules/BatteryReserve/brCore'
import {getAfterPeakToggleStatus} from '../molecules/EditAfterPeakHours/eaphCore'
import ChooseRightProfile from '../molecules/ChooseRightProfile'
import FindRightProfile from '../molecules/FindRightProfile'
import {getConfirmPopupDescription, getSavingsConfirmActivationDescription} from '../molecules/SavingsProfile/spCore'

// select left or Right Profile
function chooseProfile({
  val,
  details,
  setBrSlice,
  showDialog,
  batteryReserve,
  closeThisDialog,
  setPdSlice,
  screen,
  tutorialObj,
  closeState = true,
}) {
  let profileDescription = ''
  let profileTitle = ''

  if (val === TUTORIAL_TYPE.CHOOSE_PROFILE) {
    profileDescription = <ChooseRightProfile />
    profileTitle = siteHasMultipleProfiles() ? i18next.t('bp129') : i18next.t('bp145')
  } else if (val === TUTORIAL_TYPE.SUGGEST_PROFILE) {
    profileDescription = (
      <FindRightProfile batteryConfigResponse={JSON.stringify(details)} screen={screen} tutorialObj={tutorialObj} />
    )
    profileTitle = i18next.t('bp159')
  }

  const obj = {
    className: `sph__${toKebabCase(val)}-popup`,
    type: isWeb() ? DIALOG_TYPE.CENTER : DIALOG_TYPE.BOTTOM,
    showDialog: true,
    showCloseIcon: true,
    title: profileTitle,
    content: profileDescription,
    buttons: null,
    onClickCloseIcon: () => {
      if (val === TUTORIAL_TYPE.SUGGEST_PROFILE && closeState) {
        const brObj = getLastSetReserveObj({
          details,
          batteryReserve,
          profile: Object.keys(batteryReserve?.reserveProfile)[0],
        })
        setBrSlice(brObj)
        setPdSlice({
          operationModeSubType: getAfterPeakToggleStatus(details),
        })
      }
      closeThisDialog()
    },
  }
  showDialog(obj)
}

// Popup :: Select Tutorial
export const selectTutorialDetails = ({
  showDialog,
  closeThisDialog,
  details,
  setBrSlice,
  batteryReserve,
  setPdSlice,
  screen,
  tutorialObj,
}) => {
  if (!(showSavingsMode() || systemWithEnchargeAndGridAgnostic())) {
    chooseProfile({
      val: TUTORIAL_TYPE.CHOOSE_PROFILE,
      details,
      setBrSlice,
      showDialog,
      batteryReserve,
      closeThisDialog,
      setPdSlice,
      screen: null,
      tutorialObj: null,
    })
  } else {
    const description = isSource(SOURCE_TYPE.ITK)
      ? renderItktutorial()
      : renderSelectionContent({
          chooseProfile,
          details,
          setBrSlice,
          showDialog,
          batteryReserve,
          closeThisDialog,
          setPdSlice,
          screen,
          tutorialObj,
        })
    const title = isSource(SOURCE_TYPE.ITK) ? i18next.t('bp256') : i18next.t('bp14')
    const classname = isSource(SOURCE_TYPE.ITK) ? 'sph__itk-select-tutorial-popup' : 'sph__select-tutorial-popup'
    const obj = {
      className: classname,
      type: isWeb() ? DIALOG_TYPE.CENTER : DIALOG_TYPE.BOTTOM,
      showDialog: true,
      showCloseIcon: true,
      title,
      content: description,
      buttons: null,
      onClickCloseIcon: closeThisDialog,
    }
    showDialog(obj)
  }
}

// Render tutorial confirm popup
export function renderTutorialConfirmPopUp({
  val,
  details,
  closeThisDialog,
  showDialog,
  tutorialObj,
  setBrSlice,
  setPdSlice,
  batteryReserve,
}) {
  const popUpText = getConfirmPopupDescription(details)
  const content = getSavingsConfirmActivationDescription(popUpText)

  function closeTutorialConfirmPopUp() {
    closeThisDialog()
    setPdSlice({loading: false, inProgress: null})
    chooseProfile({
      val,
      details,
      showDialog,
      closeThisDialog,
      screen: 2,
      tutorialObj,
      setPdSlice,
      setBrSlice,
      batteryReserve,
      closeState: true,
    })
  }

  const obj = {
    className: `bp__frp-show-up`,
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: true,
    title: i18next.t('bp197'),
    content,
    onClickCloseIcon: closeTutorialConfirmPopUp,
  }
  const promise = new Promise(resolve => {
    obj.buttons = [
      {
        value: i18next.t('bp105'),
        action: () => {
          closeTutorialConfirmPopUp()
          resolve(false)
        },
        disabled: false,
        className: 'bp__ab--mobile-view',
      },
      {
        value: i18next.t('bp104'),
        action: () => {
          popupFromIframe({displayFooter: false, dialogType: DIALOG_TYPE.CENTER})
          closeThisDialog()
          chooseProfile({
            val,
            details,
            showDialog,
            closeThisDialog,
            screen: 2,
            tutorialObj,
            setPdSlice,
            setBrSlice,
            batteryReserve,
            closeState: false,
          })
          resolve(true)
        },
        disabled: false,
        className: 'bp__ab--mobile-view',
      },
    ]
  })
  obj && showDialog({...obj})
  return promise
}
