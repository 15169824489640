/* eslint-disable fp/no-mutation */

import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import i18next from 'i18next'
import {getProfileDetails} from 'lib/services/profileDetailsAPI'
import {batteryProfileSelector, setBpDetailsValue, setBpValue} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {setLoaderValue} from 'lib/store/slices/loaderSlice'
import {
  ACTION_TYPE,
  DEVICE_TYPE,
  PAGE_NAME,
  PROFILE_STATUS,
  PROFILE_TYPE,
  PROFILE_TYPE_NEM3,
  STORM_GUARD_STATUS,
  TOAST_TYPE,
  TUTORIAL_TYPE,
} from 'lib/utils/constants'
import {
  IQGWScheduleSupportWithSavingsMode,
  getDeviceType,
  showBatteryBackupPercentage,
  hasSystemCard,
  isCoExistingSite,
  isDTSupportedSite,
  isEvseSite,
  isHemsSite,
  isNem3Supported,
  isNem3TariffSupported,
  isEvChargingModesSupported,
} from 'lib/utils/siteDetails'
import {textEllipsis} from 'lib/utils/utility'
import {isUserHasAccess} from 'lib/utils/userDetails'
import Analytics from 'lib/utils/analytics'
import {setSiteSettingDetails} from 'lib/store/slices/siteSettings'
import Icon from '../../../../utils/icon'
import {getBatteryReserveValue, hasBatteryReserveValueChanged} from '../../molecules/BatteryReserve/brCore'
import {setBrValue} from '../../molecules/BatteryReserve/brSlice'
import {
  getDeviceProfileTranslation,
  getInfoDescription,
  getMode,
  getRetryStatus,
  hasDeviceBehaviourChanged,
} from '../../molecules/DeviceBehaviour/dbCore'
import {removeDevice, setDeviceUpdate, setDeviceUpdateDisable} from '../../molecules/DeviceBehaviour/dbSlice'
import EditAfterPeakHours from '../../molecules/EditAfterPeakHours'
import {
  getAfterPeakToggleStatus,
  isCurrentRequestHasAfterPeakChanges,
} from '../../molecules/EditAfterPeakHours/eaphCore'
import {EditBatteryReserve} from '../../molecules/EditBatteryReserve'
import {getStormAlertDetails} from '../../organisms/StormGuardDetails/sgCore'
import {getProfileCurrentDetails} from '../../organisms/ProfileDetails/pdCore'
import {setPdValue} from '../../organisms/ProfileDetails/pdSlice'
import {
  applySystemProfileRequest,
  getDiscardPopupDetails,
  goToPage,
  restrictUserToPerformAction,
} from '../../organisms/bpCommon'
import {renderTutorialConfirmPopUp} from '../../organisms/hspCommon'
import {
  isAnyRequestPendingForChargeBatteryFromGridWithIQGWSite,
  isAnyRequestPendingForDischargeBatteryToGrid,
  isRequestPendingForProfile,
} from '../../organisms/reqPending'
import {
  getActiveCharger,
  getDeviceBehaviourInfoDetails,
  getDevices,
  getErrorAlert,
  getIsDeviceBehaviourChanged,
  getToggleStatus,
  isNoneProfileDisabled,
  renderNoneProfileDisabledPopUp,
} from '../EvTopUpSchedule/evScheduleCore'
import {IntractiveButton} from '../IntractiveButton'
import {showToast} from '../Toast'
import {ToggleSwitch, toggleStatusInfo} from '../ToggleSwitch'
import {getThisProfileDetails, renderSavingAIPowerBeta} from '../SystemProfile/sysCore'
import './styles.scss'

/* *********************** *********************** *********************** *
 * System Profile Card
 * *********************** *********************** *********************** */

const SystemProfileCard = props => {
  const {fromTutorial, tutorialObj} = props
  const batteryProfile = useSelector(batteryProfileSelector)
  const profileDetails = useSelector(state => state.components.profileDetails)
  const batteryReserve = useSelector(state => state.components.batteryReserve)
  const deviceUpdate = useSelector(state => state.components.deviceUpdate)
  const {details} = useSelector(batteryProfileSelector)
  const devices = getDevices(details)
  const {deviceChanges} = deviceUpdate[profileDetails.openCard || details.profile]

  const dispatch = useDispatch()
  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const showLoader = obj => dispatch(setLoaderValue({...obj}))
  const setBpDetails = obj => dispatch(setBpValue({...obj}))
  const setBrSlice = obj => dispatch(setBrValue({...obj}))
  const setPdSlice = obj => dispatch(setPdValue({...obj}))
  const setBatteryData = obj => dispatch(setBpDetailsValue(obj))
  const setDeviceBehaviorDisable = obj => dispatch(setDeviceUpdateDisable(obj))
  const updateDevice = obj => dispatch(setDeviceUpdate(obj))
  const removeDeviceUpdate = obj => dispatch(removeDevice(obj))
  const setSiteSettingsValue = obj => dispatch(setSiteSettingDetails(obj))

  let spDetails = {}
  let isLoading = false
  const reqProfile =
    batteryProfile.details?.requestedConfigMqtt?.profile ||
    batteryProfile.details?.requestedConfig?.profile ||
    batteryProfile.details?.profile
  const canShowCancelButton = !fromTutorial

  // useEffect on mount
  useEffect(() => {
    if (profileDetails.openCard !== reqProfile) {
      setPdSlice({openCard: reqProfile})
    }

    if (deviceChanges.length > 0 && hasDeviceBehaviourChanged(deviceChanges, devices)) {
      devices?.forEach(device => {
        removeDeviceUpdate({profile: profileDetails.openCard, uuid: device.uuid})
      })
    }

    if (!fromTutorial) {
      setPdSlice({
        operationModeSubType: getAfterPeakToggleStatus(details),
      })

      setBrSlice({
        brReserve: getBatteryReserveValue({profile: reqProfile, details}),
      })
    }
  }, [batteryProfile.loading])

  // useEffect on mount and profile card clicked
  useEffect(() => {
    if (!(details.profile === profileDetails.openCard)) {
      devices?.forEach(device => {
        if (device.enable) {
          const deviceObj = {
            uuid: device.uuid,
            deviceType: isCoExistingSite() ? device.deviceType : getDeviceType(),
            enable: true,
            loading: false,
          }

          if (profileDetails.openCard === PROFILE_TYPE.SELF_CONSUMPTION) {
            deviceObj.profileConfig = 'full'
          }

          updateDevice({profile: profileDetails.openCard, deviceUpdates: deviceObj})
        } else {
          removeDeviceUpdate({profile: profileDetails.openCard, uuid: device.uuid})
        }
      })
    }
  }, [profileDetails.openCard])

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  const getThisCardDetails = profile => {
    spDetails = getProfileCurrentDetails(details, profile, profileDetails.inProgress, profileDetails.requestType)
    isLoading =
      (profileDetails.loading && profileDetails.inProgress === profileDetails.openCard) ||
      spDetails.subType === PROFILE_STATUS.PROCESSING
  }

  const onClickCardSection = (e, profile) => {
    onChangeRadioBtn(e, profile)
  }

  const getProfiles = () => {
    if (fromTutorial) {
      return [props.profile]
    }
    return IQGWScheduleSupportWithSavingsMode() ? _.values(PROFILE_TYPE_NEM3) : _.values(PROFILE_TYPE)
  }

  const onChangeRadioBtn = (e, profile) => {
    if (profileDetails.openCard !== profile) {
      setPdSlice({openCard: profile})
    }

    if (profile !== PROFILE_TYPE.COST_SAVINGS) {
      setPdSlice({
        operationModeSubType: getAfterPeakToggleStatus(details),
      })
    }

    if (!batteryReserve.reserveProfile[profile]) {
      setBrSlice({
        brReserve: getBatteryReserveValue({profile, details}),
        reserveProfile: {},
      })
    }

    if (profile === 'self-consumption') Analytics.sendClickEvent('self_consumption_edit_profile', 'edit_profile')
    else if (profile === 'cost_savings') Analytics.sendClickEvent('Savings_edit_profile', 'edit_profile')
    else Analytics.sendClickEvent('Full_Backup_Edit_profile', 'edit_profile')
  }

  // System Profile Card :: onClick Storm Guard button
  const onDialogButtonClick = () => {
    if (batteryProfile.subPage.includes(PAGE_NAME.EDIT_PROFILE)) {
      setBpDetails({subPage: []})
    }

    closeThisDialog()
  }

  // isInfoChanged
  const isInfoChanged = () => {
    const selectedProfile = fromTutorial ? props.profile : profileDetails.openCard

    if (isLoading) {
      return true
    }

    if (details.profile !== selectedProfile) {
      return true
    }

    if (deviceChanges.length > 0) {
      return hasDeviceBehaviourChanged(deviceChanges, devices)
    }

    if (!_.isNull(profileDetails.inProgress)) {
      return profileDetails.inProgress === selectedProfile
    }

    if (hasBatteryReserveValueChanged(details, selectedProfile)) {
      return true
    }

    if (isCurrentRequestHasAfterPeakChanges(details, profileDetails.operationModeSubType)) {
      return true
    }

    return (
      !isRequestPendingForProfile(details) && details.profile && details.profile !== selectedProfile && !spDetails.type
    )
  }

  // System Profile Card :: onClick cancel button
  const onClickCancelButton = () => {
    Analytics.sendClickEvent('Cancel_Edit_profile', 'edit_profile')
    if (isInfoChanged()) {
      const obj = getDiscardPopupDetails(
        setBpDetails,
        closeThisDialog,
        deviceChanges,
        devices,
        removeDeviceUpdate,
        profileDetails.openCard,
        setPdSlice,
        reqProfile
      )
      showDialog(obj)
      return
    }
    goToPage({subPage: null, setBpDetails})
  }

  // System Profile Card :: onClick apply button
  const onClickApplyButton = async () => {
    Analytics.sendClickEvent('Apply_edit_profile', 'edit_profile')

    // Check User Access
    if (!isUserHasAccess()) {
      return
    }
    deviceChanges && deviceChanges.length > 0 && sendEvseGAEvent(profileDetails.openCard, deviceChanges[0].enable)
    if (isNoneProfileDisabled(deviceChanges, devices)) {
      const result = await renderNoneProfileDisabledPopUp({showDialog, closeThisDialog})
      if (result) {
        saveProfileCard()
      } else return
    }

    setPdSlice({loading: true, inProgress: profileDetails.openCard})

    if (fromTutorial) {
      const result = await renderTutorialConfirmPopUp({
        val: TUTORIAL_TYPE.SUGGEST_PROFILE,
        details,
        setBrSlice,
        setPdSlice,
        batteryReserve,
        showDialog,
        closeThisDialog,
        screen: 2,
        tutorialObj,
      })
      if (result) {
        saveProfileCard()
      }
    } else {
      saveProfileCard()
    }
  }

  // System Profile Card :: Save Profile Card
  const saveProfileCard = async () => {
    // Show Page Loader
    showLoader({show: true, noBg: true})

    try {
      // Check for Storm Guard and Other updated details
      const resProfile = await getProfileDetails()
      const newObj = {
        isTariffTou: resProfile.data.isTariffTou,
        buybackExportPlan: resProfile.data.buybackExportPlan || null,
        stormGuardState: resProfile.data.stormGuardState,
        showStormGuardAlert: resProfile.data.showStormGuardAlert,
        stormAlertMessage: resProfile.data.stormAlertMessage,
        acceptedStormGuardDisclaimer: resProfile.data.acceptedStormGuardDisclaimer,
      }
      setBatteryData(newObj)

      // Check any other restrictions
      if (
        restrictUserToPerformAction({
          details: {...details, ...newObj},
          showDialog,
          closeThisDialog: onDialogButtonClick,
        })
      ) {
        // Hide Page Loader
        showLoader({show: false})
        return
      }

      const brValue = batteryReserve.brReserve
      if (showBatteryBackupPercentage() && brValue < resProfile.data.veryLowSoc) {
        showToast({
          type: TOAST_TYPE.INFO,
          message: `Reserve cannot be lesser than Battery Shutdown Level (${resProfile.data.veryLowSoc}%).`,
          autoClose: 3000,
        })

        // Hide Page Loader
        showLoader({show: false})
        return
      }

      // Apply Changes
      applySystemProfileRequest({
        profile: fromTutorial ? props.profile : profileDetails.openCard,
        props,
        details: {...details, ...newObj},
        profileDetails,
        dispatch,
        showDialog,
        closeThisDialog,
        showLoader,
        setBpDetails,
        setBatteryData,
        setPdSlice,
        setDeviceBehaviorDisable,
        deviceChanges,
        setSiteSettingsValue,
      })

      // goToPage({subPage: null, setBpDetails})
    } catch (e) {
      showLoader({show: false})
      setBpDetails({apiError: true})
      console.error('[Profile] init', e)
    }
  }

  // Sending GA logs for EVSE events
  const sendEvseGAEvent = (profileType, enable) => {
    if (profileType === PROFILE_TYPE.SELF_CONSUMPTION) {
      enable
        ? Analytics.sendClickEvent('IQEVSE_Profile_Self_Con_On', 'IQ_EVSE_Profile')
        : Analytics.sendClickEvent('IQEVSE_Profile_Self_Con_Off', 'IQ_EVSE_Profile')
    } else if (profileType === PROFILE_TYPE.COST_SAVINGS) {
      enable
        ? Analytics.sendClickEvent('IQEVSE_Profile_Savings_On', 'IQ_EVSE_Profile')
        : Analytics.sendClickEvent('IQEVSE_Profile_Savings_Off', 'IQ_EVSE_Profile')
    } else {
      enable
        ? Analytics.sendClickEvent('IQEVSE_Profile_Full_Backup_On', 'IQ_EVSE_Profile')
        : Analytics.sendClickEvent('IQEVSE_Profile_Full_Backup_Off', 'IQ_EVSE_Profile')
    }
  }

  // System Profile Card :: isLoading apply button
  const isApplyButtonDisabled = () =>
    isLoading ||
    !isInfoChanged() ||
    isAnyRequestPendingForDischargeBatteryToGrid(details) ||
    isAnyRequestPendingForChargeBatteryFromGridWithIQGWSite(details) ||
    restrictUserToPerformAction({
      details,
      inProgress: profileDetails.inProgress,
      checkUserAccess: fromTutorial,
      showUserAccessToast: false,
      showDialog: false,
      closeThisDialog,
    })

  // System Profile Card :: isLoading apply button
  const isApplyButtonLoading = () => {
    if (fromTutorial) return false
    // return isLoading
    return false
  }

  // Device handleToggle change
  const handleToggleChange = (value, obj) => {
    const {profile, isActive} = obj
    const selectedCharger = devices.find(device => device.uuid === obj.id)

    if (details?.stormGuardState === STORM_GUARD_STATUS.ACTIVE) {
      showDialog(getStormAlertDetails({details, closeThisDialog}))
      return
    }

    if (isCoExistingSite() || (isEvseSite() && !(isDTSupportedSite() || isHemsSite()))) {
      if (value === 'on' && obj.deviceType === DEVICE_TYPE.IQ_EVSE) {
        const activeCharger = getActiveCharger(obj, deviceChanges, devices)
        if (activeCharger && activeCharger.uuid !== obj.id) {
          showDialog(getErrorAlert(activeCharger, closeThisDialog))
          return
        }
      }
    }

    const toggleStatus = value === 'on'
    const isDeviceBehaviourChanged = getIsDeviceBehaviourChanged(selectedCharger, toggleStatus, isActive)
    const deviceObj = {
      uuid: obj.id,
      deviceType: isCoExistingSite() ? selectedCharger.deviceType : getDeviceType(),
      enable: toggleStatus,
      loading: false,
    }

    if (profile === PROFILE_TYPE.SELF_CONSUMPTION) {
      deviceObj.profileConfig = selectedCharger.profileConfig ? 'full' : getMode(deviceObj.enable)
    }

    isDeviceBehaviourChanged
      ? updateDevice({profile, deviceUpdates: deviceObj})
      : removeDeviceUpdate({profile, uuid: obj.id})
  }

  // Handle retry button click
  const onClickRetry = obj => {
    const profile = obj.expandedProfile

    // Check User Access
    if (restrictUserToPerformAction({details, inProgress: profileDetails.inProgress, showDialog, closeThisDialog})) {
      return
    }

    const selectedCharger = devices?.find(device => device.uuid === obj.id)

    if (isCoExistingSite() || (isEvseSite() && !(isDTSupportedSite() || isHemsSite()))) {
      const activeCharger = getActiveCharger({...obj, profile: obj.expandedProfile}, deviceChanges, devices)
      if (activeCharger && activeCharger.uuid !== obj.id) {
        showDialog(getErrorAlert(activeCharger, closeThisDialog))
        return
      }
    }

    const deviceObj = {
      uuid: obj.id,
      deviceType: isCoExistingSite() ? selectedCharger.deviceType : getDeviceType(),
      enable: !selectedCharger.enable,
      loading: false,
    }

    if (profile === PROFILE_TYPE.SELF_CONSUMPTION) {
      deviceObj.profileConfig = selectedCharger.profileConfig ? selectedCharger.profileConfig : 'full'
    }

    updateDevice({profile, deviceUpdates: deviceObj})
  }

  const showBatteryPeakHours = () => {
    if (isNem3Supported() && isNem3TariffSupported()) {
      return false
    }
    return true
  }

  /* *********************** *********************** *********************** *
   * Render Function
   * *********************** *********************** *********************** */

  function renderUseBatteryAfterPeakHours(profile) {
    if (!isDTSupportedSite() && profile === PROFILE_TYPE.COST_SAVINGS && showBatteryPeakHours()) {
      return <EditAfterPeakHours fromTutorial={fromTutorial} />
    }

    return null
  }

  function renderReserve(profile) {
    const brValue = batteryReserve.brReserve || 10
    const title = i18next.t('bp148')
    const value = brValue
    const reserveValue = () => value || getBatteryReserveValue({profile, details})

    return (
      <div className="sp--reserve">
        {profile === PROFILE_TYPE.BACKUP_ONLY || fromTutorial ? (
          showBatteryBackupPercentage() && (
            <div className="atom-design--t3">
              <div className="com__ad__icon">
                <Icon src="batteryReserve" value={brValue} />
              </div>

              <div className="com__ad__details">
                <div className="com__ad--title">{title}</div>
              </div>

              <div
                className="com__ad__action"
                onClick={() => {}}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >{`${reserveValue()}%`}</div>
            </div>
          )
        ) : (
          <EditBatteryReserve fromTutorial profile={profileDetails.openCard} title={title} />
        )}
      </div>
    )
  }

  // EV Charger :: onClick info popup bottom drawer
  const onClickEvChargerInfo = (profile, deviceObj) => {
    showDialog(getDeviceBehaviourInfoDetails(profile, deviceObj, getInfoDescription, closeThisDialog))
  }

  // Render Toggle
  function renderToggle(obj) {
    const {id, disabled, isActive, profile, deviceType} = obj
    const {status} = obj
    return (
      <ToggleSwitch
        key={`db-toggle-${id}`}
        id={`db-toggle-${id}`}
        status={status}
        disabled={disabled}
        handleChange={tObj => handleToggleChange(tObj, {id, profile, isActive, deviceType})}
      />
    )
  }

  function renderEvChargers(obj) {
    const {
      id,
      title,
      retry,
      enable,
      deviceProfile,
      deviceToggleState,
      enableStatus,
      isActive,
      expandedProfile,
      deviceType,
    } = obj
    let onTextClass = null
    let offTextClass = null
    let lastActiveProfile = null
    // loading is always false since edit button is disabled on profile page until profile and device de/activation
    const loading = false
    const toggleStatus = getToggleStatus(enable, id, deviceChanges)
    let onText = i18next.t('bp7')
    let offText = i18next.t('bp8')
    const loadingOnText = i18next.t('bp89')
    const loadingOffText = i18next.t('bp90')
    const loadingText = deviceToggleState ? loadingOffText : loadingOnText
    //  retry state needs to be handled
    if (retry) {
      onText = i18next.t('bp230')
      offText = i18next.t('bp230')
      onTextClass = 'bp__status-text--failed'
      offTextClass = 'bp__status-text--failed'
      if (enableStatus) {
        lastActiveProfile = i18next.t(getDeviceProfileTranslation(deviceProfile))
      }
    }
    return (
      <div className="sp--ev-chargers" key={id}>
        <div className="evs--ev-charger atom-design--t3">
          <div className="com__ad__icon">
            <Icon src="evCharger" />
          </div>
          <div className="com__ad__details">
            <div className="com__ad__charger-name">
              <span className="com__ad__title">{textEllipsis(title, 20)}</span>
              <span
                className="com__ad--info"
                onClick={() => onClickEvChargerInfo(expandedProfile, {deviceType})}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0">
                <Icon src="info" />
              </span>
            </div>
            {toggleStatusInfo({
              loading,
              status: toggleStatus,
              loadingText,
              onText,
              offText,
              loadingOnText,
              loadingOffText,
              onTextClass,
              offTextClass,
            })}
            {retry && enableStatus ? <div className="com__ad__title db__value">{lastActiveProfile}</div> : null}
          </div>
          <div className="com__ad__action">
            {retry ? (
              <div
                className="db__retry"
                role="button"
                onClick={() => onClickRetry({id, expandedProfile})}
                onKeyDown={() => {}}
                tabIndex="0">
                <Icon src="retry" />
              </div>
            ) : (
              renderToggle({id, isActive, deviceType, status: toggleStatus, disabled: false, profile: expandedProfile})
            )}
          </div>
        </div>
      </div>
    )
  }

  function showActiveCardDetails({profile, expandCard, isActive}) {
    if (expandCard) {
      return (
        <div className="bp__card--options">
          {renderUseBatteryAfterPeakHours(profile)}
          {renderReserve(profile)}
          {!fromTutorial &&
            hasSystemCard() &&
            !isEvChargingModesSupported() &&
            devices.map(device =>
              renderEvChargers({
                status: device.status,
                id: device.uuid,
                enable: profile === device.profile && device.enable && isActive,
                title: device.deviceName,
                activeSchedule: device.activeSchedule,
                retry: isActive && getRetryStatus(device.status, deviceChanges, device.uuid),
                deviceProfile: device.profile,
                deviceToggleState: device.enable,
                enableStatus: device.enable,
                isActive,
                expandedProfile: profile,
                deviceType: device.deviceType,
              })
            )}
        </div>
      )
    }

    return null
  }

  function renderProfile(profile) {
    getThisCardDetails(profile)

    const {title, showProfile, description} = getThisProfileDetails(profile)
    const spStatus = spDetails.type
    const spStatusText = spDetails.typeText
    const isActive = spStatus && spStatus.toLowerCase() === PROFILE_STATUS.ACTIVE
    const isProcessing = spStatus && spStatus.toLowerCase() === PROFILE_STATUS.PROCESSING
    const expandCard = profileDetails.openCard === profile || fromTutorial
    const canShowLoader = false

    return showProfile ? (
      <div
        key={profile}
        className={classNames('sp__profile', {
          'sp__profile--active': isActive,
          'sp__profile--processing': !isActive && isProcessing,
        })}
      >
        <div
          className="sp__profile--wrapper"
          onClick={e => !isProcessing && onClickCardSection(e, profile)}
          onKeyDown={() => {}}
          role="document"
          tabIndex="0"
        >
          <div className="sp__profile--title atom-design--t3">
            {!fromTutorial && (
              <div className="com__ad__icon bp__radio-checkbox__button">
                <input
                  id={`${profile}-radio-btn`}
                  type="radio"
                  name="profiles-radio-btn"
                  checked={expandCard}
                  onChange={e => !isProcessing && onChangeRadioBtn(e, profile)}
                  disabled={false}
                />
              </div>
            )}

            <div className="com__ad__details">
              <div className="com__ad--title">
                <span>{title}</span>
                {renderSavingAIPowerBeta(profile)}
                <span className="com__ad--info">{spStatusText && !fromTutorial && <>({spStatusText})</>}</span>
              </div>
            </div>

            {canShowLoader && (
              <div className="com__ad__action">
                <Icon src="loaderV3" />
              </div>
            )}
          </div>

          <div className="sp__desc">{description}</div>
        </div>

        {showActiveCardDetails({profile, expandCard, isActive})}
      </div>
    ) : null
  }

  function renderSystemProfileDetails() {
    const profiles = getProfiles()

    return (
      <section className="bp__card-view">
        <div className="bp__card-view__wrapper">{profiles.map(profile => renderProfile(profile))}</div>
      </section>
    )
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  return (
    <div
      className={classNames('bp__system-profile--card', {
        'bp__system-profile--tutorial': fromTutorial,
      })}
    >
      {renderSystemProfileDetails()}

      <div className="bp__page--actions">
        {canShowCancelButton && (
          <IntractiveButton
            className="bp__action-button bp__ab--round-corner bp__ab--outline bp__ab--full-width"
            type={ACTION_TYPE.CANCEL}
            label={i18next.t('bp99')}
            disabled={false}
            loading={false}
            onClick={onClickCancelButton}
          />
        )}

        <IntractiveButton
          className={`bp__action-button bp__ab--round-corner bp__ab--full-width ${
            isApplyButtonDisabled() ? 'bp__ab--disabled' : ''
          }`}
          type={ACTION_TYPE.SAVE}
          label={fromTutorial ? i18next.t('bp20') : i18next.t('bp210')}
          disabled={isApplyButtonDisabled()}
          loading={isApplyButtonLoading()}
          onClick={onClickApplyButton}
        />
      </div>
    </div>
  )
}

SystemProfileCard.propTypes = {
  fromTutorial: PropTypes.bool,
  profile: PropTypes.string,
  tutorialObj: PropTypes.shape({
    firstVal: PropTypes.number,
    secondVal: PropTypes.number,
  }),
}

SystemProfileCard.defaultProps = {
  fromTutorial: false,
  profile: '',
  tutorialObj: {
    firstVal: 1,
    secondVal: 2,
  },
}

export default SystemProfileCard
