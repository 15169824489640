/* eslint-disable fp/no-mutation */
import {createSlice} from '@reduxjs/toolkit'
import {updateSpecificKeyValue} from 'lib/utils'

const initialState = {
  'self-consumption': {
    deviceChanges: [],
  },
  backup_only: {
    deviceChanges: [],
  },
  cost_savings: {
    deviceChanges: [],
  },
  deviceChangeDisable: false,
}

export const deviceUpdateSlice = createSlice({
  name: 'deviceUpdate',
  initialState,
  reducers: {
    setDeviceUpdate: (state, action) => {
      const {payload} = action
      const {profile, deviceUpdates} = payload
      try {
        const deviceChanges = JSON.parse(JSON.stringify(state[profile].deviceChanges))
        const index = deviceChanges.findIndex(device => device.uuid === deviceUpdates.uuid)
        if (index > -1) {
          updateSpecificKeyValue(deviceUpdates, deviceChanges[index])
          state[profile].deviceChanges = deviceChanges
        } else {
          // eslint-disable-next-line fp/no-mutating-methods
          state[profile].deviceChanges.push(deviceUpdates)
        }
      } catch (e) {
        console.error('[Error] deviceUpdateSlice', e)
      }
      return state
    },
    clearDeviceUpdate: state => {
      state.deviceChanges = []
      state.deviceChangeDisable = false
      return state
    },
    setDeviceUpdateDisable: (state, action) => {
      const {payload} = action
      state.deviceChangeDisable = payload.deviceChangeDisable
      return state
    },
    removeDevice: (state, action) => {
      const {payload} = action
      const {profile} = payload
      try {
        const deviceChanges = JSON.parse(JSON.stringify(state[profile].deviceChanges))
        const updatedDeviceChanges = deviceChanges.filter(device => device.uuid !== payload.uuid)
        state[profile].deviceChanges = updatedDeviceChanges
      } catch (e) {
        console.error('[Error] removeDevice', e)
      }
      return state
    },
    setDeviceLoading: (state, action) => {
      const {payload} = action
      const {profile, uuid} = payload
      try {
        const deviceChanges = JSON.parse(JSON.stringify(state[profile].deviceChanges))
        const index = deviceChanges.findIndex(device => device.uuid === uuid)
        if (index > -1) {
          deviceChanges[index].loading = true
          state[profile].deviceChanges = deviceChanges
        }
      } catch (e) {
        console.error('[Error] setDeviceLoading', e)
      }
      return state
    },
  },
})

// Action creators are generated for each case reducer function
export const {setDeviceUpdate, clearDeviceUpdate, setDeviceLoading, removeDevice, setDeviceUpdateDisable} =
  deviceUpdateSlice.actions
export default deviceUpdateSlice.reducer
