/* eslint-disable fp/no-mutation */
import React from 'react'
import _ from 'lodash'
import i18next from 'i18next'
import {HTTPS_API_STATUS} from 'lib/services/httpRequest'
import {getStormAlert, setStormAlert} from 'lib/services/stormGuardAPI'
import Analytics from 'lib/utils/analytics'
import {STORM_ALERT_STATUS, TOAST_TYPE} from 'lib/utils/constants'
import {scrollToView} from 'lib/utils/utility'
import {Trans} from 'react-i18next'
import Icon from '../../../../utils/icon'

// Sort Storm Alerts
export function sortStormAlerts(obj) {
  return _.orderBy(obj, ['status', 'startTime'], ['asc', 'asc'])
}

// Get Info description
export function getInfoDescription() {
  const active = <span className="bp__status-text--enabled" />
  const optedOut = <span className="bp__status-text--opted-out" />

  return (
    <div className="sa__popup__wrapper">
      <div className="e_dialog__content">
        <Trans i18nKey="bp392">
          Alerts issued for your locality are added to the &apos;Ongoing Storm Alerts&apos; list. These alerts can be
          either &quot;{active}&quot; or &quot;{optedOut}&quot;.
        </Trans>
        <br />
        <br />
        <Trans i18nKey="bp393">
          {active}: These are alerts for which storm alert is on for your system, and the profile has been changed to
          Full Backup. You have the option to opt out of the alerts. Storm Guard will continue to monitor for future
          alerts.
        </Trans>
        <br />
        <br />
        <Trans i18nKey="bp394">
          {optedOut}: These alerts are issued for your locality, but you have opted out. Therefore, these alerts will
          not affect your system profile.
        </Trans>
      </div>
    </div>
  )
}

// Get Critical Alert Info description
export function getCAInfoDescription() {
  return (
    <div className="e_dialog__body__wrapper">
      <Icon src="alert" />
      <div className="e_dialog__content">{i18next.t('bp478')}</div>
    </div>
  )
}

// Get Opt button text
export function getBtnText({status}) {
  let btnText = null

  if (status === STORM_ALERT_STATUS.ACTIVE) {
    btnText = i18next.t('bp385')
  }

  if (status === STORM_ALERT_STATUS.OPTED_OUT) {
    btnText = i18next.t('bp386')
  }

  return btnText
}

// Get Storm Alert Status
export function getStormAlertStatus(obj) {
  const {status, loading} = obj

  switch (status) {
    case STORM_ALERT_STATUS.ACTIVE:
      if (loading) {
        return [i18next.t('bp390')]
      }
      return [i18next.t('bp381'), 'sa__status-text--active']
    case STORM_ALERT_STATUS.OPTED_OUT:
      if (loading) {
        return [i18next.t('bp391')]
      }
      return [i18next.t('bp382'), 'sa__status-text--opted-out']
    default:
      return null
  }
}

// Get Storm Alerts
export async function getStormAlerts(args) {
  const {setSaSlice} = args

  setSaSlice({getApiLoading: true})

  // Get Storm Alerts
  try {
    const res = await getStormAlert()
    if (res && res.status === HTTPS_API_STATUS.SUCCESS) {
      setSaSlice({
        stormAlerts: res.stormAlerts,
        criticalAlertActive: res.criticalAlertActive,
      })
    } else {
      throw new Error('Error')
    }
  } catch (error) {
    console.error('[Error] getStormAlerts', error)
    // TODO
    // setSaSlice({stormAlerts: [], criticalAlertActive: false})
  } finally {
    setSaSlice({getApiLoading: false})
  }
}

// Set Storm Alert Changes
export async function setThisStormAlert(args) {
  const {setSaSlice, setThisAlertDetails, showToast, obj} = args

  setSaSlice({loading: true})
  setThisAlertDetails({...obj, isLoading: true})

  function getPayloadStatus() {
    return obj.status === STORM_ALERT_STATUS.ACTIVE ? STORM_ALERT_STATUS.OPTED_OUT : STORM_ALERT_STATUS.ACTIVE
  }

  const body = {
    stormAlerts: [
      {
        id: obj.id,
        name: obj.name,
        status: getPayloadStatus(),
      },
    ],
  }

  // Set Storm Alert
  try {
    const resStormAlert = await setStormAlert(body)
    if (resStormAlert?.status === HTTPS_API_STATUS.SUCCESS) {
      Analytics.sendClickEvent(`StormGuard_Alerts_${getPayloadStatus()?.replace(/-/g, '_')}`, 'StormGuard')

      setTimeout(() => {
        const localeId = getPayloadStatus() === STORM_ALERT_STATUS.OPTED_OUT ? 'bp388' : 'bp389'

        showToast({
          type: TOAST_TYPE.SUCCESS,
          message: i18next.t(localeId, {stormAlertName: obj.name}),
          autoClose: 3000,
        })

        setSaSlice({loading: false})
        setThisAlertDetails({...obj, status: getPayloadStatus(), isLoading: false})

        // Scroll to the card
        const thisCard = document.getElementById(`sa-${obj.name.toLowerCase().replace(/ /g, '-')}`)
        scrollToView(thisCard, {})
      }, 1000)
    } else {
      throw new Error('Error')
    }
  } catch (error) {
    console.error('[Error] setThisStormAlert', error)

    setTimeout(() => {
      setSaSlice({loading: false})
      setThisAlertDetails({id: obj.id, isLoading: false})
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp68'),
        autoClose: 3000,
      })
    }, 1000)
  }
}
